/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { cloneElement } from 'react';

import Html from 'Component/Html';
/** @namespace PageBuilder/Component/HtmlCode/Component/HtmlCodeComponent */
export class HtmlCodeComponent extends React.PureComponent {
    render() {
        const {
            BaseHtmlCode
        } = this.props.elements;

        return (
            <BaseHtmlCode.Ele>
                { BaseHtmlCode.childEleBag.map((HtmlContent) => {
                    if (typeof HtmlContent !== 'string') {
                        return HtmlContent.map((item) => {
                            if (typeof item === 'string') {
                                return <Html key={ Math.random() } content={ item } />;
                            }

                            return cloneElement(item, {
                                key: Math.random()
                            });
                        });
                    }

                    return <Html key={ Math.random() } content={ HtmlContent } />;
                }) }
            </BaseHtmlCode.Ele>
        );
    }
}

export default HtmlCodeComponent;
