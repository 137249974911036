import getStore from 'Util/Store';

import { LOGIN, triggerEvent } from '../../Gtm.config';

const onRequestOtp = async (args, callback, instance) => {
    const {
        showNotification
    } = instance.props;

    try {
        await callback(...args);

        const store = getStore();
        const { getState } = store;
        const reduxState = getState();
        const countryCode = reduxState.ConfigReducer.code;

        const [form] = args;
        const clevertapData = {
            Site: {
                Phone: `+971${form.phone_number}`,
                'MSG-push': true,
                'MSG-sms': true,
                'MSG-whatsapp': true,
                'Country Code': countryCode
            }
        };

        triggerEvent({
            event: LOGIN,
            clevertapData
        });
    } catch (error) {
        showNotification('error', error);
    }
};

export default {
    'Scandi/Component/MyAccountSignInWithOtp/Container/MyAccountSignInWithOtpContainer': {
        'member-function': {
            onRequestOtp
        }
    }
};
