import {
    CheckoutQuery as SourceCheckoutQuery
} from 'SourceQuery/Checkout.query';

/** @namespace Scandi/Query/Checkout/Query/CheckoutQuery */
export class CheckoutQuery extends SourceCheckoutQuery {
    // TODO implement logic
}

export default new CheckoutQuery();
