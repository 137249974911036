/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './CountryFlags.style';

/**
 * Cart and CartOverlay item
 * @class CartItem
 * @namespace Scandi/Component/CountryFlags/Component/CountryFlagsComponent */
export class CountryFlagsComponent extends PureComponent {
    static propTypes = {
        code: PropTypes.string.isRequired
    };

    render() {
        const { code } = this.props;
        switch (code) {
        case 'bd':
            return (<img block="CountryFlags" src="https://www.lafz.com/media/wysiwyg/countries/bd.webp" alt="Bangladesh" />);
        case 'bh':
            return (<img block="CountryFlags" src="https://www.lafz.com/media/wysiwyg/countries/bh.webp" alt="Bahrain" />);
        case 'in':
            return (<img block="CountryFlags" src="https://www.lafz.com/media/wysiwyg/countries/in.webp" alt="India" />);
        case 'kw':
            return (<img block="CountryFlags" src="https://www.lafz.com/media/wysiwyg/countries/kw.webp" alt="Kuwait" />);
        case 'om':
            return (<img block="CountryFlags" src="https://www.lafz.com/media/wysiwyg/countries/om.webp" alt="Oman" />);
        case 'pk':
            return (<img block="CountryFlags" src="https://www.lafz.com/media/wysiwyg/countries/pk.webp" alt="Pakistan" />);
        case 'qa':
            return (<img block="CountryFlags" src="https://www.lafz.com/media/wysiwyg/countries/qa.webp" alt="Qatar" />);
        case 'sa':
            return (<img block="CountryFlags" src="https://www.lafz.com/media/wysiwyg/countries/sa.webp" alt="Saudi" />);
        case 'ae':
            return (<img block="CountryFlags" src="https://www.lafz.com/media/wysiwyg/countries/ae.webp" alt="UAE" />);
        default:
            return null;
        }
    }
}

export default CountryFlagsComponent;
