import { UrlParser as urlParser } from 'url-params-parser';
import { v4 as uuidv4 } from 'uuid';

import BrowserDatabase from 'Util/BrowserDatabase';
import { isStagingSite } from 'Util/helpers';

import { GTM_ADDITIONAL, GTM_CODE, UTM_DATA } from '../../Gtm.config';

// eslint-disable-next-line fp/no-let
const uuid = uuidv4();

const render = (args, callback, instance) => {
    // get uid or generate new
    const uid = localStorage.getItem('UID');

    if (!uid) {
        localStorage.setItem('UID', uuid);
    }

    const isStaging = isStagingSite();

    const check = document.querySelector('#indglobal-gtm');
    if (check || isStaging) {
        return callback.apply(instance, args);
    }
    const gtmscript = document.createElement('script');
    gtmscript.setAttribute('async', true);
    gtmscript.setAttribute('defer', true);
    gtmscript.setAttribute('id', 'indglobal-gtm');
    gtmscript.innerHTML = GTM_CODE;
    document.body.appendChild(gtmscript);

    const noscript = document.createElement('noscript');
    noscript.innerHTML = GTM_ADDITIONAL;
    document.body.prepend(noscript);

    const query = urlParser(window.location.href).queryParams;

    if (query.utm_source) {
        BrowserDatabase.setItem(query, UTM_DATA);
    } else {
        BrowserDatabase.setItem({
            utm_source: 'Direct/Organic',
            utm_medium: 'Direct/Organic',
            utm_campaign: 'Direct/Organic'
        }, UTM_DATA);
    }

    return callback.apply(instance, args);
};

export default {
    'Scandi/Component/App/Component/AppComponent': {
        'member-function': {
            render
        }
    }
};
