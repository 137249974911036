/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

/* eslint-disable import/prefer-default-export */
import currencyMap, { HUNDRED_PERCENT } from './Price.config';
import PriceFractions from './PriceFractionsConfig';

/**
 * Calculate final price
 * @param {Number} tier tier price
 * @param {Number} spec special price
 * @return {Number} final discount
 * @namespace Scandi/Util/Price/calculateTierDiscountOverSpecialPrice */
export const calculateTierDiscountOverSpecialPrice = (spec, tier) => (
    Math.round(HUNDRED_PERCENT - (tier / spec) * HUNDRED_PERCENT)
);
/** @namespace Scandi/Util/Price/getFraction */
export const getFraction = (currency = null, type = null) => {
    if (currency && type) {
        if (PriceFractions[currency]) {
            const { [type]: typeFR = 0 } = PriceFractions[currency];
            return typeFR;
        }
    }

    return 0;
};

/** @namespace Scandi/Util/Price/formatCurrency */
export const formatCurrency = (currency = 'USD') => currencyMap[currency];

/** @namespace Scandi/Util/Price/formatPrice */
// eslint-disable-next-line arrow-body-style
export const formatPrice = (price, currency = 'USD') => {
    // const language = navigator.languages ? navigator.languages[0] : navigator.language;
    if (currency === 'KWD' || currency === 'BHD' || currency === 'AED') {
        return `${currency} ${parseFloat(price)?.toFixed(2)}`;
    }

    return `${currency} ${parseFloat(price).toFixed(2)}`;
    // return new Intl.NumberFormat(language, { style: 'currency', currency, minimumFractionDigits: getFraction(currency, 'min'), maximumFractionDigits: getFraction(currency, 'max') }).format(price);
};

/**
 * Calculate final price
 * @param {Number} discount discount percentage
 * @param {Number} min minimum price
 * @param {Number} reg regular price
 * @return {Number} final price
 * @namespace Scandi/Util/Price/calculateFinalPrice */
export const calculateFinalPrice = (discount, min, reg) => (discount ? min : reg);

/**
 * Calculate final price
 * @param {Number} price
 * @return {Number} price rounded to 2 digits
 * @namespace Scandi/Util/Price/roundPrice */
export const roundPrice = (price) => parseFloat(price).toFixed(2);

/** @namespace Scandi/Util/Price/getProductPrice */
export const getProductPrice = ({
    discount = {},
    regular_price = {},
    final_price = {}
}) => {
    const { amount_off = 0 } = discount;
    const { value, currency } = amount_off > 0 ? final_price : regular_price;
    const formatted = formatPrice(value, currency);

    return {
        raw: value,
        currency,
        formatted
    };
};

/** @namespace Scandi/Util/Price/getLowestPriceTiersPrice */
export const getLowestPriceTiersPrice = (price_tiers, currency) => {
    const lowestValue = price_tiers
        .reduce((acc, { final_price: { value } }) => (acc < value ? acc : value), price_tiers[0].final_price.value);

    return formatPrice(lowestValue, currency);
};
