/* eslint-disable no-unused-vars */
import CheckoutOrderSummaryPriceLine from 'Component/CheckoutOrderSummaryPriceLine';

const renderSubTotal = (args, callback, instance) => {
    const returnData = callback.apply(instance, args);
    const { totals } = instance.props;

    const productsDiscount = totals.items.reduce((all, curr) => {
        if (!curr || !curr.product) {
            return all;
        }
        const { price_range: { maximum_price: { discount: { amount_off = 0 } = {} } = {} } = {} } = curr.product || {};

        return all + (curr.qty * amount_off);
    }, 0);

    const mrp = totals.items.reduce((all, curr) => {
        if (!curr || !curr.product) {
            return all;
        }
        const {
            price_range: {
                maximum_price: {
                    regular_price: {
                        value = 0
                    } = {}
                } = {}
            } = {}
        } = curr.product || {};

        return all + (curr.qty * value);
    }, 0);

    return (
        <>
            { /* { mrp > 0 ? (
                <CheckoutOrderSummaryPriceLine
                  price={ mrp }
                  title={ __('Price') }
                  currency={ totals.quote_currency_code }
                />
            ) : null }
            { productsDiscount > 0 ? (
                <CheckoutOrderSummaryPriceLine
                  price={ productsDiscount }
                  title={ __('Discount') }
                  currency={ totals.quote_currency_code }
                />
            ) : null } */ }
            { returnData }
        </>
    );
};

/* eslint-disable react/no-unknown-property */
const renderContent = (args, callback, instance) => {
    const { totals } = instance.props;

    const coupondiscount = Math.abs(totals.discount_amount);

    const productsDiscount = totals.items.reduce((all, curr) => {
        if (!curr || !curr.product) {
            return all;
        }
        const { price_range: { maximum_price: { discount: { amount_off = 0 } = {} } = {} } = {} } = curr.product || {};

        return all + (curr.qty * amount_off);
    }, 0);

    const totalDiscount = coupondiscount + productsDiscount;

    return (
        <>
            { callback.apply(instance, args) }
            { totalDiscount > 0 ? (
            <p block="CheckoutOrderSummary" elem="YouSave">
                You save
                { ' ' }
                { totalDiscount.toFixed(0) }
                { ' ' }
                { totals.quote_currency_code }
                { ' ' }
                on this order
            </p>
            ) : null }
        </>
    );
};

export default {
    'Scandi/Component/CheckoutOrderSummary/Component/CheckoutOrderSummaryComponent': {
        'member-function': {
            renderContent,
            renderSubTotal
        }
    }
};
