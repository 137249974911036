/* eslint-disable */
import PropTypes from 'prop-types';
import { PureComponent, createRef } from 'react';
import CustomSlider from 'Component/CustomSlider';

import './ProductsVideo.style';
import TextPlaceholder from '@scandipwa/scandipwa/src/component/TextPlaceholder';
import PRODUCT_TYPE from 'Component/Product/Product.config';
import ProductPrice from 'Component/ProductPrice';
import {
    getPrice, getProductInStock
} from 'Util/Product/Extract';
import AddToCart from 'Component/AddToCart';

/** @namespace Scandi/Component/ProductsVideo/Component/ProductsVideoComponent */
export class ProductsVideoComponent extends PureComponent {
    static propTypes = {
        slides: PropTypes.number.isRequired,
        products: PropTypes.arrayOf( PropTypes ).isRequired,
        paddingInline: PropTypes.string.isRequired,
        sliderProps: PropTypes.object.isRequired,
        productsobject: PropTypes.string.isRequired
    };

    sliderRef = createRef();
    renderPrice(product) {
        console.log('attributes', product);
        const {
                type_id: baseType,
                price_range: priceRange,
                type_id: type,
                price_tiers: priceTiers
        } = product;
        console.log('attributes', baseType, priceRange, type, priceTiers);

        if (!priceRange) {
            return <TextPlaceholder />;
        }

        // If product is not a variant.
        const notConfigured = baseType !== PRODUCT_TYPE.configurable || typeId === baseType;
        const productPrice = getPrice(priceRange);
        return (
            <div
              block="ProductCard"
              elem="PriceWrapper"
            >
                <ProductPrice
                  meta
                  price={ productPrice }
                  priceType={ type }
                  tierPrices={ priceTiers }
                  isPreview={ notConfigured }
                  mix={ { block: "ProductCard", elem: 'Price' } }
                />
            </div>
        );
    }

    cards() {
        const { products = [], productsobject } = this.props;
        return products.map((product)=>(
            <div className="productVideos-Wrap" key={product.sku}>
                <a href={product.url}>
                    <video preload="none" loop={true} autoPlay={true} playsInline={true} muted={true} mediatype="video">
                        <source src={productsobject[product.sku]} type="video/mp4" />
                    </video>
                </a>
                <div className="productVideos-Content">
                <div className="productVideos-Wrapper">
                    <a href={product.url}>
                        <span>
                            <img src={product.small_image.url ?? product.thumbnail.url} alt={product.name} />
                        </span>
                    </a>
                    <a href={product.url} className="productVideos-Content-text">
                        <span>{product.name}</span>
                        { this.renderPrice(product) }
                    </a>
            <AddToCart
              mix={ { block: "productVideos", elem: 'AddToCart' } }
              isDisabled={ !getProductInStock(product) }
              onlyIcon={ true }
              quantity={ 1 }
              product={ product }
            />
                </div>
                </div>
            </div>
        ));
    }

    render() {
        const { products, paddingInline, sliderProps, slides } = this.props;
        if (!products && products.length < 1) {
            return;
        }
        if (slides > products.length) {
            return (
                <div className="productVideos-flex">
                    {this.cards()}
                </div>
            )
        }
        return (
            <CustomSlider style={ { '--slide-gap': paddingInline } } mix={ { block: 'HtmlSlider' } } { ...sliderProps } sliderRef={ this.sliderRef }>
                {this.cards()}
            </CustomSlider>
        );
    }
}

export default ProductsVideoComponent;
