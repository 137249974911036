import {
    BOTTOM as SOURCE_BOTTOM,
    LEFT as SOURCE_LEFT,
    RIGHT as SOURCE_RIGHT,
    TOP as SOURCE_TOP
} from 'SourceComponent/ChevronIcon/ChevronIcon.config';

// TODO: implement LEFT
export const LEFT = SOURCE_LEFT;

// TODO: implement RIGHT
export const RIGHT = SOURCE_RIGHT;

// TODO: implement TOP
export const TOP = SOURCE_TOP;

// TODO: implement BOTTOM
export const BOTTOM = SOURCE_BOTTOM;
