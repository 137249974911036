import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import ProductListQuery from 'Query/ProductList.query';
import { getIndexedProducts } from 'Util/Product';
import { fetchQuery } from 'Util/Request';

import VideoSliderProduct from './VideoSliderProduct.component';

/** @namespace PageBuilder/Component/VideoSliderProduct/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({
    // wishlistItems: state.WishlistReducer.productsInWishlist
});

/** @namespace PageBuilder/Component/VideoSliderProduct/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
    // addProduct: options => CartDispatcher.addProductToCart(dispatch, options)
});

/** @namespace PageBuilder/Component/VideoSliderProduct/Container/VideoSliderProductContainer */
export class VideoSliderProductContainer extends PureComponent {
    static propTypes = {
        sku: PropTypes.string.isRequired,
        setProduct: PropTypes.func.isRequired
    };

    state = {
        isReady: false,
        product: null
    };

    containerFunctions = {
        // getData: this.getData.bind(this)
    };

    componentDidMount() {
        this.fetchProduct();
    }

    containerProps() {
        const { isReady, product } = this.state;

        return {
            isReady,
            product
        };
    }

    async fetchProduct() {
        const { sku, setProduct } = this.props;
        if (!sku) {
            this.setState({
                isReady: false,
                product: null
            });

            return;
        }
        try {
            const {
                products: { items }
            } = await fetchQuery(ProductListQuery.getQuery({
                args: {
                    filter: {
                        productsSkuArray: [sku]
                    }
                }
            }));

            const indexed = getIndexedProducts(items);

            const [product] = indexed;

            setProduct(product);

            this.setState({
                isReady: true,
                product
            });
        } catch (e) {
            this.setState({
                isReady: false,
                product: null
            });
        }
    }

    render() {
        return (
            <VideoSliderProduct
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoSliderProductContainer);
