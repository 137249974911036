/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-magic-numbers */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTimer } from 'react-timer-hook';

/** @namespace Scandi/Component/Timer/Index/Timer */
// eslint-disable-next-line react/prop-types
export const Timer = ({ time, onFinish, fullTimer = false }) => {
    const [expired, setExpired] = useState(false);

    const expireDate = new Date(time);

    const timer = useTimer({
        expiryTimestamp: expireDate,
        onExpire: () => {
            setExpired(true);
            onFinish();
        }
    });

    const {
        seconds,
        minutes,
        hours,
        days
    } = timer;

    const renderDays = () => (days);

    const renderHrs = () => (hours > 9 ? hours : `0${hours}`);

    const renderMins = () => (minutes > 9 ? minutes : `0${minutes}`);

    const renderSecs = () => (seconds > 9 ? seconds : `0${seconds}`);

    if (expired) {
        return null;
    }
    if (fullTimer) {
        if (!days) {
            if (!hours) {
                return (
                    <div>{ `${renderMins()}:${renderSecs()}` }</div>
                );
            }

            return (
                <div>{ `${renderHrs()}:${renderMins()}:${renderSecs()}` }</div>
            );
        }

        return (
            <div>{ `${renderDays()}Days, ${renderHrs()}:${renderMins()}:${renderSecs()}` }</div>
        );
    }

    return (
        <div>{ `${renderMins()}:${renderSecs()}` }</div>
    );
};

Timer.propTypes = {
    time: PropTypes.string.isRequired,
    onFinish: PropTypes.func
};

Timer.defaultProps = {
    onFinish: () => {}
};

export default Timer;
