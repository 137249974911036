import PropTypes from 'prop-types';

const propTypes = (originalMember) => ({
    ...originalMember,
    isSkinAnalyserProduct: PropTypes.bool
});

const defaultProps = (originalMember) => ({
    ...originalMember,
    isSkinAnalyserProduct: false
});

const containerProps = (args, callback, instance) => {
    const { isSkinAnalyserProduct } = instance.props;
    return {
        ...callback.apply(instance, args),
        isSkinAnalyserProduct
    };
};

export default {
    'Scandi/Component/ProductCard/Container/ProductCardContainer': {
        'static-member': {
            propTypes,
            defaultProps
        },
        'member-function': {
            containerProps
        }
    }
};
