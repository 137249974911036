import {
    CUSTOMER_ACCOUNT_OVERLAY_KEY as SOURCE_CUSTOMER_ACCOUNT_OVERLAY_KEY,
    STATE_CONFIRM_EMAIL as SOURCE_STATE_CONFIRM_EMAIL,
    STATE_CREATE_ACCOUNT as SOURCE_STATE_CREATE_ACCOUNT,
    STATE_FORGOT_PASSWORD as SOURCE_STATE_FORGOT_PASSWORD,
    STATE_FORGOT_PASSWORD_SUCCESS as SOURCE_STATE_FORGOT_PASSWORD_SUCCESS,
    STATE_LOGGED_IN as SOURCE_STATE_LOGGED_IN,
    STATE_SIGN_IN as SOURCE_STATE_SIGN_IN
} from 'SourceComponent/MyAccountOverlay/MyAccountOverlay.config';

// TODO: implement STATE_SIGN_IN
export const STATE_SIGN_IN = SOURCE_STATE_SIGN_IN;

// TODO: implement STATE_FORGOT_PASSWORD
export const STATE_FORGOT_PASSWORD = SOURCE_STATE_FORGOT_PASSWORD;

// TODO: implement STATE_FORGOT_PASSWORD_SUCCESS
export const STATE_FORGOT_PASSWORD_SUCCESS = SOURCE_STATE_FORGOT_PASSWORD_SUCCESS;

// TODO: implement STATE_CREATE_ACCOUNT
export const STATE_CREATE_ACCOUNT = SOURCE_STATE_CREATE_ACCOUNT;

// TODO: implement STATE_LOGGED_IN
export const STATE_LOGGED_IN = SOURCE_STATE_LOGGED_IN;

// TODO: implement STATE_CONFIRM_EMAIL
export const STATE_CONFIRM_EMAIL = SOURCE_STATE_CONFIRM_EMAIL;

// TODO: implement CUSTOMER_ACCOUNT_OVERLAY_KEY
export const CUSTOMER_ACCOUNT_OVERLAY_KEY = SOURCE_CUSTOMER_ACCOUNT_OVERLAY_KEY;
