import PropTypes from 'prop-types';

const propTypes = (originalMember) => ({
    ...originalMember,
    handleOtpLogin: PropTypes.func.isRequired
});

const containerProps = (args, callback, instance) => {
    const {
        handleOtpLogin,
        countryCode
    } = instance.props;

    return {
        ...callback.apply(instance, args),
        handleOtpLogin,
        countryCode
    };
};

const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(...args),
        countryCode: state.ConfigReducer.code
    };
};

export default {
    'Component/MyAccountSignIn/Container': {
        'static-member': {
            propTypes
        },
        'member-function': {
            containerProps
        }
    },
    'Component/MyAccountSignIn/Container/mapStateToProps': {
        function: mapStateToProps
    }
};
