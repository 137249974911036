import getStore from 'Util/Store';

export const ScalpMeterDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../store/ScalpMeterProducts/ScalpMeterProducts.dispatcher'
);

const removeProductFromCart = async (args, callback, _instance) => {
    try {
        const [dispatch, item_id] = args;
        const store = getStore();
        const { cartTotals } = store.getState().CartReducer;
        const { inCart: inScalpMeterCart = [] } = store.getState().ScalpMeterReducer;

        const currentProduct = cartTotals.items.find((item) => item.item_id === item_id);

        const result = await callback(...args);
        if (!result || !currentProduct) {
            return result;
        }

        const isInCart = inScalpMeterCart.find((item) => currentProduct.sku.includes(item.sku));

        if (!isInCart) {
            return result;
        }

        ScalpMeterDispatcher.then(
            ({ default: dispatcher }) => dispatcher.removeProduct(isInCart, dispatch)
        );

        return result;
    } catch (error) {
        throw new Error(error);
    }
};

export default {
    'Store/Cart/Dispatcher': {
        'member-function': {
            removeProductFromCart
        }
    }
};
