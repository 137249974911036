import PropTypes from 'prop-types';

import { ProductType } from 'Type/ProductList.type';

export const ScalpMeterDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../store/ScalpMeterProducts/ScalpMeterProducts.dispatcher'
);

const mapStateToProps = (args, callback, _instance) => {
    const [state] = args;

    return {
        ...callback(...args),
        scalpProducts: state.ScalpMeterReducer.products,
        scalpProductsInCart: state.ScalpMeterReducer.inCart
    };
};

const mapDispatchToProps = (args, callback, _instance) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        addScalpProduct: (product) => ScalpMeterDispatcher.then(
            ({ default: dispatcher }) => dispatcher.addProduct(product, dispatch)
        )
    };
};

const propTypes = (originalMember) => ({
    ...originalMember,
    scalpProducts: PropTypes.arrayOf(ProductType).isRequired,
    scalpProductsInCart: PropTypes.arrayOf(ProductType).isRequired,
    addScalpProduct: PropTypes.func.isRequired
});

const _afterAddToCart = (args, callback, instance) => {
    const {
        scalpProducts = [],
        scalpProductsInCart = [],
        product: { sku = '' } = {},
        product = {},
        addScalpProduct = () => {}
    } = instance.props;

    const isScalpProduct = scalpProducts.find((item) => item.sku === sku);

    if (!isScalpProduct) {
        return callback.apply(instance, args);
    }

    const isAlreadyAdded = scalpProductsInCart.find((item) => item.sku === sku);

    if (isAlreadyAdded) {
        return callback.apply(instance, args);
    }

    addScalpProduct(product);

    return callback.apply(instance, args);
};

export default {
    'Scandi/Component/AddToCart/Container/AddToCartContainer': {
        'member-function': {
            _afterAddToCart
        },
        'static-member': {
            propTypes
        }
    },
    'Scandi/Component/AddToCart/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Scandi/Component/AddToCart/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    }
};
