/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Field } from 'Util/Query';

/** @namespace Razorpay/Query/Razorpay/Query/RazorpayQuery */
export class RazorpayQuery {
    getCreateRazorpayPaymentOrderMutation(input) {
        return new Field('createRazorpayPaymentOrder')
            .addArgument('input', 'RazorpayOrderInput!', input)
            .addFieldList(this._getPaymentOrderFields())
            .setAlias('razorpayOrder');
    }

    _getPaymentOrderFields() {
        return [
            'success',
            'key_id',
            'rzp_order',
            'order_id',
            'amount',
            'quote_currency',
            'quote_amount'
        ];
    }
}

export default new RazorpayQuery();
