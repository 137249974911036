import { ScalpMeterProductsReducer } from '../store/ScalpMeterProducts/ScalpMeterProducts.reducer';

const getStaticReducers = (args, callback, _instance) => ({
    ...callback(...args),
    ScalpMeterReducer: ScalpMeterProductsReducer
});

export const config = {
    'Scandi/Store/Index/getStaticReducers': {
        function: getStaticReducers
    }
};

export default config;
