export const OPEN_CATEGORIES_POPUP = 'OPEN_CATEGORIES_POPUP';
export const CLOSE_CATEGORIES_POPUP = 'CLOSE_CATEGORIES_POPUP';

/** @namespace Scandi/Store/CategoriesPopup/Action/openCategoryPopup */
export const openCategoryPopup = () => ({
    type: OPEN_CATEGORIES_POPUP
});

/** @namespace Scandi/Store/CategoriesPopup/Action/closeCategoryPopup */
export const closeCategoryPopup = () => ({
    type: CLOSE_CATEGORIES_POPUP
});
