/* eslint-disable react/jsx-no-bind */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable react/no-unknown-property */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/media-has-caption */

// import PropTypes from 'prop-types';
import attributesToProps from 'html-react-parser/lib/attributes-to-props';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { closeModal, openModal } from 'Store/ModalReducer/ModalReducer.action';

import VideoSliderSlideComponent from './VideoSliderSlide.component';

import './VideoSliderSlide.style';

/** @namespace PageBuilder/Component/VideoSliderSlide/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device
});

/** @namespace PageBuilder/Component/VideoSliderSlide/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    openPopup: (content) => dispatch(openModal(content)),
    closePopup: () => dispatch(closeModal())
});

/** @namespace PageBuilder/Component/VideoSliderSlide/Container/VideoSliderSlideContainer */
export class VideoSliderSlideContainer extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    containerFunctions = {
        setProduct: this.setProduct.bind(this),
        openPopup: this.openPopup.bind(this),
        closePopup: this.closePopup.bind(this)
    };

    __construct(props) {
        const { slide } = props;

        const { attribs: video = {} } = slide.children.find((child) => child.name === 'video') || {};
        const { attribs: product = {} } = slide.children.find((child) => child.attribs && Object.keys(child.attribs).includes('data-product-sku')) || {};

        const videoprops = attributesToProps(video);

        this.state = {
            videoprops,
            sku: product['data-product-sku'],
            product: null
        };
    }

    setProduct(product) {
        this.setState({ product });
    }

    openPopup(content) {
        const { openPopup } = this.props;
        openPopup(content);
    }

    closePopup() {
        const { closePopup } = this.props;
        closePopup();
    }

    containerProps() {
        const { slide, device } = this.props;

        const {
            product,
            videoprops,
            sku
        } = this.state;

        return {
            slide,
            product,
            videoprops,
            sku,
            device
        };
    }

    render() {
        return (
            <VideoSliderSlideComponent
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoSliderSlideContainer);
