import BrowserDatabase from 'Util/BrowserDatabase';
import { fetchMutation } from 'Util/Request';

import UtmTrackerQuery from '../query/UtmTracker.query';

const componentDidMount = (args, callback, instance) => {
    const { orderID } = instance.props;
    const utmData = BrowserDatabase.getItem('UTM_DATA') || {};
    const skus = BrowserDatabase.getItem('CART_REWARD_SKU') || [];

    if (utmData) {
        fetchMutation(UtmTrackerQuery.getQuery({
            order_id: orderID,
            utm_source: utmData.utm_source,
            utm_medium: utmData.utm_medium,
            utm_campaign: utmData.utm_campaign,
            rewards_skus: skus.join(', ')
        }))
            .then((response) => {
                const { updateUtmData } = response;
                if (updateUtmData && updateUtmData.success) {
                    localStorage.removeItem('UTM_DATA');
                    BrowserDatabase.deleteItem('CART_REWARD_SKU');
                }
            });
    }

    callback.apply(instance, args);
};

export default {
    'Scandi/Component/CheckoutSuccess/Component/CheckoutSuccessComponent': {
        'member-function': {
            componentDidMount
        }
    }
};
