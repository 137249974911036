import { Field } from 'Util/Query';

/** @namespace ScalpMeter/Query/ScalpMeter/Query/ScalpMeterQuery */
export class ScalpMeterQuery {
    getQuery({
        age,
        flakes_build,
        gender,
        hair_dense,
        hair_describes,
        hair_frizzy,
        hair_issues,
        hair_products,
        name,
        observe_dandruff,
        phone,
        scalp_itch,
        scalp_redness,
        scalp_type,
        store
    }) {
        return new Field('createScalpmeter')
            .addArgument('input', 'createScalpmeterInput!', {
                age,
                flakes_build,
                gender,
                hair_dense,
                hair_describes,
                hair_frizzy,
                hair_issues,
                hair_products,
                name,
                observe_dandruff,
                phone,
                scalp_itch,
                scalp_redness,
                scalp_type,
                store
            })
            .addFieldList([
                'message',
                'product_sku',
                'results',
                'scalp_score',
                'success',
                'transaction_id'
            ]);
    }

    saveScalpmeterPurchasedFromRecommendation({ sku, transaction_id }) {
        return new Field('saveScalpmeterPurchasedFromRecommendation')
            .addArgument('input', 'ScalpmeterPurchasedFromRecommendation!', { sku, transaction_id })
            .addFieldList([
                'success',
                'message'
            ]);
    }
}

export default new ScalpMeterQuery();
