import { Field } from 'Util/Query';

/** @namespace SkinAnalyser/Query/SkinAnalyser/Query/SkinAnalyserQuery */
export class SkinAnalyserQuery {
    skinQuestion(question = 0, storeCode = 'ae') {
        return new Field('skinQuestion')
            .addArgument('parentAnswer', 'Int!', question)
            .addArgument('storeCode', 'String!', storeCode)
            .addFieldList([
                'success',
                'message',
                new Field('allQuestion')
                    .addFieldList([
                        'question',
                        'id',
                        'layout',
                        new Field('allAnswer')
                            .addFieldList([
                                'label',
                                'value',
                                'image',
                                'description'
                            ])
                    ])
            ]);
    }

    skinAnalysis(input) {
        return new Field('skinAnalysis')
            .addArgument('input', 'SkinAnalysis!', input)
            .addFieldList([
                'message',
                'success',
                'transaction_id'
            ]);
    }

    skinAnalyserResult(transaction_id) {
        return new Field('skinAnalyserResult')
            .addArgument('transaction_id', 'String!', transaction_id)
            .addFieldList([
                'message',
                'success',
                'product_sku'
            ]);
    }

    savePurchasedFromRecommendation(input) {
        return new Field('savePurchasedFromRecommendation')
            .addArgument('input', 'PurchasedFromRecommendation!', input)
            .addFieldList([
                'success',
                'message'
            ]);
    }
}

export default new SkinAnalyserQuery();
