/* eslint-disable new-cap */
/* eslint-disable no-param-reassign */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import PropTypes from 'prop-types';
import React from 'react';

import MyAccountSignInWithOtp from '../component/MyAccountSignInWithOtp';
import { STATE_SIGNIN_WITH_OTP } from '../component/MyAccountSignInWithOtp/MyAccountSignInWithOtp.config';

import './MyAccountOverlay.style.scss';

const propTypes = (originalMember) => ({
    ...originalMember,
    handleOtpLogin: PropTypes.func.isRequired
});

const renderMap = (originalMember, instance) => ({
    ...originalMember,
    [STATE_SIGNIN_WITH_OTP]: {
        render: () => instance.renderSignInWithOtp(),
        title: __('Login / Register')
    }
});

const renderSignIn = (args, callback, instance) => {
    const {
        handleOtpLogin
    } = instance.props;
    const data = callback.apply(instance, args);
    const returnData = React.cloneElement(data, {
        ...data.props,
        handleOtpLogin
    });

    return returnData;
};

const renderSignInWithOtp = (args, callback, instance) => {
    const {
        state,
        onFormError,
        handleSignIn,
        isCheckout,
        handleCreateAccount,
        setLoadingState,
        onSignIn
    } = instance.props;

    return (
        <MyAccountSignInWithOtp
          state={ state }
          onFormError={ onFormError }
          handleSignIn={ handleSignIn }
          handleCreateAccount={ handleCreateAccount }
          isCheckout={ isCheckout }
          setLoadingState={ setLoadingState }
          onSignIn={ onSignIn }
        />
    );
};

export default {
    'Component/MyAccountOverlay/Component': {
        'static-member': {
            propTypes
        },
        'member-property': {
            renderMap
        },
        'member-function': {
            renderSignIn,
            renderSignInWithOtp
        }
    }
};
