import PropTypes from 'prop-types';

import AddToCart from 'Component/AddToCart';
import { getDefaultCustomizableOptions } from 'Util/helpers';

const propTypes = (originalMember) => ({
    ...originalMember,
    isSkinAnalyserProduct: PropTypes.bool
});

const renderAddToCart = (args, callback, instance) => {
    const content = callback.apply(instance, args);
    const {
        product,
        isRewardProduct,
        isSkinAnalyserProduct
    } = instance.props;

    const { type_id } = product;
    if (!type_id) {
        return null;
    }

    // eslint-disable-next-line fp/no-let
    let isbundleoos = false; let productOptionsData = {};
    if (type_id === 'bundle') {
        productOptionsData = getDefaultCustomizableOptions(product);
        isbundleoos = productOptionsData.requiredOptions.length !== productOptionsData.productOptions.length;
    }
    const groupedProductQuantity = {};

    if (type_id === 'simple' || type_id === 'bundle') {
        return (
            <AddToCart
              product={ product }
              mix={ { block: 'ProductCard', elem: 'AddToCart' } }
              quantity={ 1 }
              groupedProductQuantity={ groupedProductQuantity }
              productOptionsData={ productOptionsData }
              isRewardProduct={ isRewardProduct }
              isOutOfStock={ isbundleoos }
              isSkinAnalyserProduct={ isSkinAnalyserProduct }
            />
        );
    }

    return content;
};

export default {
    'Scandi/Component/ProductCard/Component/ProductCardComponent': {
        'static-member': {
            propTypes
        },
        'member-function': {
            renderAddToCart
        }
    }
};
