import PropTypes from 'prop-types';

import BrowserDatabase from 'Util/BrowserDatabase';
import getStore from 'Util/Store';

import {
    CONVERSION_EVENT,
    GTM_DATA,
    PURCHASE_EVENT,
    triggerEvent,
    UTM_DATA
} from '../../Gtm.config';

export const getCountryCode = (key) => {
    switch (key) {
    case 'in':
        return '+91';
    case 'bd':
        return '+880';
    case 'sa':
        return '+966';
    case 'ae':
        return '+971';
    case 'kw':
        return '+965';
    case 'pk':
        return '+92';
    case 'qa':
        return '+974';
    case 'bh':
        return '+973';
    case 'om':
        return '+968';
    default:
        return '+91';
    }
};

const propTypes = (originalMember) => ({
    ...originalMember,
    telephone: PropTypes.string.isRequired
});

const state = (originalMember) => ({
    ...originalMember,
    gtmTriggered: false
});

const render = (args, callback, instance) => {
    const { gtmTriggered } = instance.state;

    if (!gtmTriggered) {
        const {
            email,
            firstName,
            lastName,
            telephone,
            orderID,
            isSignedIn,
            customer
        } = instance.props;
        const gtmData = BrowserDatabase.getItem(GTM_DATA) || {};

        const {
            quote_currency_code,
            grand_total,
            items,
            coupon_code,
            tax_amount,
            shipping_incl_tax
        } = gtmData;

        const state = getStore()?.getState();

        const {
            code: countryCode = 'default'
        } = state?.ConfigReducer || { };

        const phoneCode = getCountryCode(countryCode);
        const phonenumber = `${phoneCode}${telephone}`;
        // eslint-disable-next-line max-len
        const customername = `${isSignedIn ? customer.firstname : firstName} ${isSignedIn ? customer.lastname : lastName}`;

        if (grand_total > 0 && quote_currency_code !== '') {
            const itemsData = [];

            const clevertapData = {
                'Order ID': orderID,
                'Customer Name': customername,
                'Customer Email': `${isSignedIn ? customer.email : email }`,
                'Customer Phone': phonenumber,
                'Coupon Code': coupon_code,
                'Grand Total': grand_total,
                Currency: quote_currency_code,
                Date: new Date(),
                'Country Code': countryCode
            };

            const clevertapChargedEventData = {
                ...clevertapData,
                Items: [],
                Products: '',
                Categories: ''
            };

            // eslint-disable-next-line fp/no-let
            let count = 1;

            if (items) {
                items.forEach((d) => {
                    const {
                        qty,
                        sku,
                        price,
                        product: {
                            name,
                            url,
                            price_range: {
                                minimum_price: {
                                    discount: { amount_off = 0, percent_off = 0 } = {}
                                } = {}
                            } = {},
                            categories
                        }
                    } = d;

                    if (price > 0) {
                        itemsData.push(
                            {
                                item_id: sku,
                                item_name: name,
                                currency: quote_currency_code,
                                price,
                                quantity: qty
                            }
                        );

                        clevertapData[`Product Name - ${count}`] = name;
                        clevertapData[`Product SKU - ${count}`] = sku;
                        clevertapData[`Product Price - ${count}`] = price;
                        clevertapData[`Product Quantity - ${count}`] = qty;
                        clevertapData[`Product URL - ${count}`] = url;
                        clevertapData[`Product Discount Amount - ${count}`] = amount_off;
                        clevertapData[`Product Discount Percent - ${count}`] = percent_off;

                        // eslint-disable-next-line max-len
                        clevertapChargedEventData.Products += ` || Product Name - ${count} = ${name}, Product SKU - ${count} = ${sku}, Product Discount Amount - ${count} = ${amount_off}, Product Discount Percent - ${count} = ${percent_off}, Product Price - ${count} = ${price}, Product Quantity - ${count} = ${qty}, Product URL - ${count} = ${url}`;

                        // eslint-disable-next-line fp/no-let
                        let catCount = 1;
                        // eslint-disable-next-line max-len
                        clevertapChargedEventData.Categories += categories && categories.length ? categories.reduce((prev, curr) => {
                            if (!curr) {
                                return prev;
                            }

                            // eslint-disable-next-line no-param-reassign
                            prev += `|| Category Name - ${catCount} = ${curr.name}, `;

                            catCount++;

                            return prev;
                        }, '') : '';

                        clevertapChargedEventData.Items.push({
                            'Product Name': name,
                            'Product SKU': sku,
                            'Product Price': price,
                            'Product Quantity': qty,
                            'Product URL': url,
                            'Discount Percent': percent_off,
                            'Discount Amount': amount_off
                        });

                        count++;
                    }
                });
            }

            const customerData = {
                Name: customername,
                Phone: `${phonenumber}`,
                Email: `${isSignedIn ? customer.email : email }`,
                'MSG-email': true,
                'MSG-push': true,
                'MSG-sms': true,
                'MSG-whatsapp': true,
                'Country Code': countryCode
            };

            const utmData = BrowserDatabase.getItem(UTM_DATA);

            triggerEvent({ ecommerce: null });

            triggerEvent({
                event: PURCHASE_EVENT,
                ecommerce: {
                    currency: quote_currency_code,
                    transaction_id: orderID,
                    value: grand_total,
                    coupon: coupon_code,
                    shipping: shipping_incl_tax,
                    tax: tax_amount,
                    items: itemsData
                },
                utm_data: utmData,
                customerData,
                clevertapData,
                clevertapChargedEventData
            });
            triggerEvent({
                event: CONVERSION_EVENT,
                ecommerce: {
                    currency: quote_currency_code,
                    transaction_id: orderID,
                    value: grand_total
                },
                utm_data: utmData
            });
            // BrowserDatabase.deleteItem(GTM_DATA);
        }

        instance.setState({
            gtmTriggered: true
        });
    }

    return callback.apply(instance, args);
};

export default {
    'Scandi/Component/CheckoutSuccess/Component/CheckoutSuccessComponent': {
        'member-function': {
            render
        },
        'member-property': {
            state
        },
        'static-member': {
            propTypes
        }
    }
};
