import {
    FieldContainer as SourceFieldContainer
} from 'SourceComponent/Field/Field.container';

/** @namespace Scandi/Component/Field/Container/FieldContainer */
export class FieldContainer extends SourceFieldContainer {
    // TODO implement logic
}

export default FieldContainer;
