import { OTP_ALLOWED_COUNTRIES } from '../component/MyAccountSignInWithOtp/MyAccountSignInWithOtp.config';

import './MyAccountSignin.style.scss';

const render = (args, callback, instance) => {
    const { handleOtpLogin, countryCode } = instance.props;
    if (!OTP_ALLOWED_COUNTRIES.includes(countryCode)) {
        return callback.apply(instance, args);
    }
    // if (countryCode === 'sa' || countryCode === 'ae') {
    //     window.addEventListener('mouseover', handleOtpLogin(), { once: true });
    // }

    return (
        <>
            { callback.apply(instance, args) }
            <div block="MyAccountOverlay" elem="OtpLogin">
                <div block="MyAccountOverlay" elem="OtpLogin-Or">
                    <span block="MyAccountOverlay" elem="OtpLogin-OrLine" />
                    <span block="MyAccountOverlay" elem="OtpLogin-OrText">Or</span>
                </div>
                <button
                  block="MyAccountOverlay"
                  elem="OtpLogin-Link"
                  onClick={ handleOtpLogin }
                >
                    Sign in with OTP
                </button>
            </div>
        </>
    );
};

export default {
    'Component/MyAccountSignIn/Component': {
        'member-function': {
            render
        }
    }
};
