export const STATE_SIGNIN_WITH_OTP = 'signInWithOtp';

export const countryCodes = {
    in: '+91',
    pk: '+92',
    ae: '+971',
    bd: '+880',
    kw: '+965',
    sa: '+966',
    bh: '+973',
    ng: '+234',
    qa: '+974',
    om: '+968'
};

export const OTP_ALLOWED_COUNTRIES = [
    'ae', 'sa', 'kw', 'om', 'qa', 'bh', 'in', 'bd'
];
