export const OPEN_TRY_NOW = 'OPEN_TRY_NOW';
export const CLOSE_TRY_NOW = 'CLOSE_TRY_NOW';

/** @namespace Orbo/Store/OrboConfig/Action/openTryNow */
export const openTryNow = () => ({
    type: OPEN_TRY_NOW
});

/** @namespace Orbo/Store/OrboConfig/Action/closeTryNow */
export const closeTryNow = () => ({
    type: CLOSE_TRY_NOW
});
