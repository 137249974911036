/* eslint-disable no-magic-numbers */
/* eslint-disable react/prop-types */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ('OSL') v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import React, { useState } from 'react';
import { useTimer } from 'react-timer-hook';

import './HomePageSaleTimer.style.scss';

/** @namespace Scandi/Component/HomePageSaleTimer/Component/HomePageSaleTimerComponent */
export function HomePageSaleTimerComponent({ props }) {
    const [expired, setExpired] = useState(false);
    const {
        heading,
        background_color,
        text_color,
        expire_date,
        desktop_background_image,
        mobile_background_image,
        isMobile,
        varCSS,
        Link
    } = props;

    const currentDate = new Date();
    const expiredDate = new Date(expire_date);
    // date.setHours(24, 0, 0, 0);
    const {
        // hours,
        seconds,
        minutes
    } = useTimer({
        expiryTimestamp: expiredDate,
        onExpire: () => setExpired(true)
    });

    function renderHrs() {
        const hours = Math.abs(expiredDate - currentDate) / 36e5;

        const rounded = Math.floor(hours);

        return rounded > 9 ? rounded : `0${rounded}`;
    }

    function renderMins() {
        return minutes > 9 ? minutes : `0${minutes}`;
    }

    function renderSecs() {
        return seconds > 9 ? seconds : `0${seconds}`;
    }

    if (expired) {
        return null;
    }

    function banner() {
        return (
            <>
            <style>{ varCSS }</style>
            <div
              block="HomePage"
              elem="SaleTimerInner"
            >
                <div block="HomePage" elem="SaleTimerContent">
                    <div block="HomePage" elem="SaleTimerContentHeading">
                        { heading }
                    </div>
                    <div block="HomePage" elem="SaleTimerContentTimeLeft">
                        <div block="HomePage" elem="SaleTimerContentTimeLeftHeading">
                            <div>Hours</div>
                            <div>{ renderHrs() }</div>
                        </div>
                        <div block="HomePage" elem="SaleTimerContentTimeLeftHeading">
                            <div>Minutes</div>
                            <div>{ renderMins() }</div>
                        </div>

                        <div block="HomePage" elem="SaleTimerContentTimeLeftHeading">
                            <div>Seconds</div>
                            <div>{ renderSecs() }</div>
                        </div>
                    </div>
                </div>
            </div>
            </>
        );
    }

    if (Link.length > 1) {
        return (
            <a
              href={ Link }
              block="HomePage"
              elem="SaleTimer"
              style={ {
                  backgroundColor: background_color,
                  backgroundImage: `url(${ isMobile ? mobile_background_image : desktop_background_image })`,
                  color: text_color,
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center center'
              } }
            >
                { banner() }
            </a>
        );
    }

    return (
        <div
          block="HomePage"
          elem="SaleTimer"
          style={ {
              backgroundColor: background_color,
              backgroundImage: `url(${ isMobile ? mobile_background_image : desktop_background_image })`,
              color: text_color,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center'
          } }
        >
            { banner() }
        </div>
    );
}

export default HomePageSaleTimerComponent;
