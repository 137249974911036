/* eslint-disable max-len */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { CUSTOMER_ACCOUNT_PATH } from 'Component/Header/Header.config';
import MyAccountQuery from 'Query/MyAccount.query';
import { showNotification } from 'Store/Notification/Notification.action';
import history from 'Util/History';
import { fetchMutation, getErrorMessage } from 'Util/Request';

import { getFormData } from '../../util/form';
import MyAccountSignInWithOtp from './MyAccountSignInWithOtp.component';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

/** @namespace OtpLogin/Component/MyAccountSignInWithOtp/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isEmailAvailable: state.CheckoutReducer.isEmailAvailable,
    device: state.ConfigReducer.device,
    minimunPasswordLength: state.ConfigReducer.minimun_password_length,
    countryCode: state.ConfigReducer.code
});

/** @namespace OtpLogin/Component/MyAccountSignInWithOtp/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    signIn: (options) => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.verifyOtp(options, dispatch)
    ),
    showNotification: (type, message) => dispatch(showNotification(type, message))
});

/** @namespace OtpLogin/Component/MyAccountSignInWithOtp/Container/MyAccountSignInWithOtpContainer */
export class MyAccountSignInWithOtpContainer extends PureComponent {
    static propTypes = {
        state: PropTypes.string.isRequired,
        onFormError: PropTypes.func.isRequired,
        handleForgotPassword: PropTypes.func.isRequired,
        handleCreateAccount: PropTypes.func.isRequired,
        handleSignIn: PropTypes.func.isRequired,
        isCheckout: PropTypes.bool.isRequired,
        signIn: PropTypes.func.isRequired,
        showNotification: PropTypes.func.isRequired,
        onSignIn: PropTypes.func.isRequired,
        setLoadingState: PropTypes.func.isRequired,
        emailValue: PropTypes.string,
        isEmailAvailable: PropTypes.bool,
        setSignInState: PropTypes.func,
        handleEmailInput: PropTypes.func,
        // eslint-disable-next-line react/forbid-prop-types
        device: PropTypes.object.isRequired,
        minimunPasswordLength: PropTypes.number.isRequired,
        countryCode: PropTypes.string.isRequired
    };

    state = {
        isOtpSent: false,
        phone_number: '',
        otp_expire: null,
        isOtpExpired: false,
        is_customer: false,
        showResend: true
    };

    static defaultProps = {
        emailValue: '',
        isEmailAvailable: true,
        setSignInState: () => {},
        handleEmailInput: () => {}
    };

    containerFunctions = {
        onRequestOtp: this.onRequestOtp.bind(this),
        onSignInSuccess: this.onSignInSuccess.bind(this),
        onTimerExpire: this.onTimerExpire.bind(this),
        onClickResendOtp: this.onClickResendOtp.bind(this)
    };

    componentDidUpdate(prevProps) {
        const { isCheckout, isEmailAvailable, setSignInState } = this.props;
        const { isEmailAvailable: prevIsEmailAvailable } = prevProps;

        if (isCheckout && isEmailAvailable && !prevIsEmailAvailable) {
            setSignInState('');
        }
    }

    onTimerExpire() {
        this.setState({
            showResend: true
        });
    }

    containerProps = () => {
        const {
            state,
            onFormError,
            handleForgotPassword,
            handleCreateAccount,
            isCheckout,
            setLoadingState,
            emailValue,
            handleEmailInput,
            handleSignIn,
            device,
            minimunPasswordLength,
            countryCode
        } = this.props;

        const {
            isOtpSent,
            otp_expire,
            isOtpExpired,
            is_customer,
            showResend,
            phone_number
        } = this.state;

        return {
            state,
            onFormError,
            handleForgotPassword,
            handleCreateAccount,
            isCheckout,
            setLoadingState,
            emailValue,
            handleEmailInput,
            handleSignIn,
            isOtpSent,
            otp_expire,
            isOtpExpired,
            is_customer,
            device,
            showResend,
            phone_number,
            minimunPasswordLength,
            countryCode
        };
    };

    async onSignInSuccess(form, fields) {
        const {
            signIn,
            showNotification,
            onSignIn,
            setLoadingState,
            device
        } = this.props;

        setLoadingState(true);
        const formData = getFormData(fields);

        const { phone_number, is_customer } = this.state;

        // eslint-disable-next-line no-param-reassign
        formData.phone_number = phone_number;
        // eslint-disable-next-line no-param-reassign
        formData.is_customer = is_customer;

        try {
            const res = await signIn(formData);
            if (res) {
                onSignIn();
                if (device.isMobile) {
                    history.replace(CUSTOMER_ACCOUNT_PATH);
                }
            } else {
                throw new Error('Invalid login details');
            }
        } catch (error) {
            showNotification('error', getErrorMessage(error));
        } finally {
            setLoadingState(false);
        }
    }

    async onRequestOtp(form, fields) {
        const {
            showNotification,
            setLoadingState
        } = this.props;

        setLoadingState(true);

        const formData = getFormData(fields);

        try {
            const result = await fetchMutation(MyAccountQuery.getMobileSignInMutation({ phone_number: formData.phone_number }));
            const { sendOtp: { success, message, is_customer } } = result;
            if (success) {
                const otp_expire = new Date();
                // eslint-disable-next-line no-magic-numbers
                otp_expire.setSeconds(otp_expire.getSeconds() + 30);
                this.setState({
                    phone_number: formData.phone_number,
                    isOtpSent: true,
                    otp_expire,
                    isOtpExpired: false,
                    is_customer,
                    showResend: false
                });

                return;
                // showNotification('success', 'OTP is sent to your mobile number');
            }
            showNotification('error', message);
            // onSignIn();
        } catch (error) {
            showNotification('error', getErrorMessage(error));
        } finally {
            setLoadingState(false);
        }
    }

    onOtpExpire() {
        this.setState({
            otp_expire: '',
            isOtpExpired: true,
            showResend: true
        });
    }

    onClickResendOtp() {
        this.setState({
            isOtpSent: false,
            otp_expire: '',
            isOtpExpired: false
        });
    }

    render() {
        return (
            <MyAccountSignInWithOtp
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountSignInWithOtpContainer);
