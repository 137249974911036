import BrowserDatabase from 'Util/BrowserDatabase';

import { SKIN_ANALYSER, SKIN_ANALYSER_LINKS, triggerEvent } from '../../Gtm.config';

const saveAnswer = (args, callback, instance) => {
    const [answerId = 0, questionId = 0] = args;

    const index = BrowserDatabase.getItem('SKIN_ANALYSER_INDEX') || 1;
    const newIndex = index + 1;

    const clevertapData = BrowserDatabase.getItem('CLEVERTAP_SKIN_ANALYSER') || {};

    const { currentQuestion } = instance.state;

    if (!currentQuestion || currentQuestion.id !== questionId) {
        return callback.apply(instance, args);
    }

    const selectedAnswer = currentQuestion.allAnswer.find((answer) => answer.value === answerId);

    clevertapData[`Question ${index}`] = currentQuestion.question;
    clevertapData[`Answer ${index}`] = selectedAnswer.label;

    BrowserDatabase.setItem(clevertapData, 'CLEVERTAP_SKIN_ANALYSER');
    BrowserDatabase.setItem(newIndex, 'SKIN_ANALYSER_INDEX');
    return callback.apply(instance, args);
};

const submitQuestion = (args, callback, instance) => {
    const {
        name,
        age,
        gender,
        email,
        phone
    } = instance.state;

    const { storeCode: store_code } = instance.props;

    const clevertapData = BrowserDatabase.getItem('CLEVERTAP_SKIN_ANALYSER');

    const { storeConfig: { base_link_url = '' } = {} } = BrowserDatabase.getItem('config') || {};

    const selectedLink = SKIN_ANALYSER_LINKS[clevertapData['Answer 1']];

    const url = new URL(selectedLink, base_link_url);

    clevertapData.name = name;
    clevertapData.age = age;
    clevertapData.gender = gender;
    clevertapData.email = email;
    clevertapData.phone = phone;
    clevertapData.store_code = store_code;
    clevertapData.link = url.href;

    BrowserDatabase.setItem(clevertapData, 'CLEVERTAP_SKIN_ANALYSER');

    return callback.apply(instance, args);
};

const afterSkinAnalyserDataSubmit = (args, callback, instance) => {
    const clevertapData = BrowserDatabase.getItem('CLEVERTAP_SKIN_ANALYSER');

    const [{ skinAnalysis }] = args;

    const { storeConfig: { base_link_url = '' } = {} } = BrowserDatabase.getItem('config') || {};

    const url = new URL(`/skin-analyser/product-recommendation/${skinAnalysis.transaction_id}`, base_link_url);

    clevertapData.recommendations = url.href;

    triggerEvent({
        event: SKIN_ANALYSER,
        clevertapData
    });

    BrowserDatabase.deleteItem('CLEVERTAP_SKIN_ANALYSER');
    BrowserDatabase.deleteItem('SKIN_ANALYSER_INDEX');

    return callback.apply(instance, args);
};

export default {
    'SkinAnalyser/Route/SkinAnalyser/Container/SkinAnalyserContainer': {
        'member-function': {
            saveAnswer,
            submitQuestion
        }
    },
    'SkinAnalyser/Route/SkinAnalyser/Container/fetchMutation/submitSuccess': {
        function: afterSkinAnalyserDataSubmit
    }
};
