/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './CartIcon.style';

/** @namespace Scandi/Component/CartIcon/Component/CartIconComponent */
export class CartIconComponent extends PureComponent {
    static propTypes = {
        isActive: PropTypes.bool
    };

    static defaultProps = {
        isActive: false
    };

    render() {
        const { isActive } = this.props;

        return (
            <svg
              block="CartIcon"
              mods={ { isActive } }
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
            <g clip-path="url(#clip0_121_1440)">
            <path d="M19.5749 20.25H4.42488C4.24033 20.2491 4.06242 20.1811 3.92439 20.0586C3.78636 19.9361 3.69766 19.7675 3.67488 19.5844L2.34363 7.58438C2.33179 7.47982 2.34208 7.37394 2.37384 7.27363C2.4056 7.17331 2.45811 7.0808 2.52796 7.0021C2.59781 6.92341 2.68344 6.8603 2.77928 6.81686C2.87513 6.77343 2.97903 6.75065 3.08426 6.75H20.9155C21.0207 6.75065 21.1246 6.77343 21.2205 6.81686C21.3163 6.8603 21.4019 6.92341 21.4718 7.0021C21.5416 7.0808 21.5942 7.17331 21.6259 7.27363C21.6577 7.37394 21.668 7.47982 21.6561 7.58438L20.3249 19.5844C20.3021 19.7675 20.2134 19.9361 20.0754 20.0586C19.9373 20.1811 19.7594 20.2491 19.5749 20.25Z" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M8.25 9.75V6.75C8.25 5.75544 8.64509 4.80161 9.34835 4.09835C10.0516 3.39509 11.0054 3 12 3C12.9946 3 13.9484 3.39509 14.6517 4.09835C15.3549 4.80161 15.75 5.75544 15.75 6.75V9.75" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
            <clipPath id="clip0_121_1440">
            <rect width="24" height="24" fill="white" />
            </clipPath>
            </defs>
            </svg>
        );
    }
}

export default CartIconComponent;
