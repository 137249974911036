/** @namespace OtpLogin/Util/Form/Index/getFormData */
export const getFormData = (data = []) => {
    if (!Array.isArray(data)) {
        return {};
    }

    return data.reduce((acc, item) => {
        if (!item) {
            return acc;
        }

        return {
            ...acc,
            [item.name]: item.value
        };
    }, {});
};
