/* eslint-disable max-len */
/* eslint-disable no-magic-numbers */
/* eslint-disable fp/no-let */
/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import DynamicQueriesQuery from 'Query/DynamicQueries.query';
import { fetchGetQuery } from 'Util/Request';

import HomePageSaleTimer from './HomePageSaleTimer.component';

/** @namespace Scandi/Component/HomePageSaleTimer/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device
});

/** @namespace Scandi/Component/HomePageSaleTimer/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

/** @namespace Scandi/Component/HomePageSaleTimer/Container/HomePageSaleTimerContainer */
export class HomePageSaleTimerContainer extends PureComponent {
    static propTypes = {
        device: PropTypes.shape({
            isMobile: PropTypes.bool.isRequired
        }).isRequired
        // timerGraphql: PropTypes.string
    };

    // static defaultProps = {
    //     timerGraphql: 'homepage_sale_var_ae'
    // };

    componentDidMount() {
        this.fetchSaleVar();
    }

    __construct() {
        this.state = {
            heading: 'SALE ENDS IN',
            enabled: 'no',
            background_color: '#91835c',
            text_color: '#fff',
            expire_date: '01 March, 2024 23:30',
            start_date: '01 March, 2023 23:30',
            desktop_background_image: '',
            mobile_background_image: '',
            varCSS: '',
            Link: ''
        };
        this.fetchSaleVar = this.fetchSaleVar.bind(this);
    }

    containerProps() {
        const {
            heading,
            background_color,
            text_color,
            start_date,
            expire_date,
            desktop_background_image,
            mobile_background_image,
            varCSS,
            Link
        } = this.state;

        return {
            heading,
            background_color,
            text_color,
            start_date,
            expire_date,
            desktop_background_image,
            mobile_background_image,
            varCSS,
            Link,
            isMobile: this._isMobile()
        };
    }

    _isMobile() {
        const { device } = this.props;

        return device.isMobile;
    }

    fetchSaleVar() {
        const timerGraphql = `homepage_sale_var_${window.location.pathname.slice(1, 3)}`;
        fetchGetQuery(DynamicQueriesQuery.getQuery({
            graphql_name: timerGraphql
        }))
            .then(
            /** @namespace Scandi/Component/HomePageSaleTimer/Container/fetchGetQuery/then */
                ({ getDynamicGraphql }) => {
                    const { graphql_items, success } = getDynamicGraphql;
                    if (success) {
                        this.setState({
                            heading: graphql_items.find((item) => item.config_key === 'heading').config_value,
                            enabled: graphql_items.find((item) => item.config_key === 'enabled').config_value,
                            background_color: graphql_items.find((item) => item.config_key === 'background_color').config_value,
                            text_color: graphql_items.find((item) => item.config_key === 'text_color').config_value,
                            start_date: graphql_items.find((item) => item.config_key === 'start_date')?.config_value || '',
                            expire_date: graphql_items.find((item) => item.config_key === 'expire_date').config_value,
                            desktop_background_image: graphql_items.find((item) => item.config_key === 'desktop_background_image')?.config_value || '',
                            mobile_background_image: graphql_items.find((item) => item.config_key === 'mobile_background_image')?.config_value || '',
                            varCSS: graphql_items.find((item) => item.config_key === 'varCSS')?.config_value || '',
                            Link: graphql_items.find((item) => item.config_key === 'link')?.config_value || ''
                        });
                    }
                },
                /** @namespace Scandi/Component/HomePageSaleTimer/Container/fetchGetQuery/then */
                (error) => {
                    // eslint-disable-next-line no-console
                    console.log('error- not found!', error);
                }
            );
    }

    render() {
        const { enabled, start_date, expire_date } = this.state;
        if (enabled.toLowerCase() !== 'yes') {
            return null;
        }

        const start = new Date(start_date);
        const end = new Date(expire_date);
        const current = new Date();

        if (start_date !== '' && start > current) {
            return null;
        }
        if (expire_date !== '' && end < current) {
            return null;
        }

        return (
            <HomePageSaleTimer
              props={ this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePageSaleTimerContainer);
