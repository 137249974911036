import { CLOSE_CATEGORIES_POPUP, OPEN_CATEGORIES_POPUP } from './CategoriesPopup.action';

/** @namespace Scandi/Store/CategoriesPopup/Reducer/getInitialState */
export const getInitialState = () => ({
    isOpen: false
});

/** @namespace Scandi/Store/CategoriesPopup/Reducer/CategoriesPopupReducer */
export const CategoriesPopupReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case CLOSE_CATEGORIES_POPUP:
        return {
            ...state,
            isOpen: false
        };

    case OPEN_CATEGORIES_POPUP:
        return {
            ...state,
            isOpen: true
        };

    default:
        return state;
    }
};

export default CategoriesPopupReducer;
