import { SUBSCRIBE_NEWSLETTER, triggerEvent } from '../../Gtm.config';

const onFormSubmitDone = (args, callback, instance) => {
    const [email] = args;

    const clevertapData = {
        Site: {
            Email: email,
            Source: 'Newsletter',
            'MSG-email': true,
            'MSG-push': true,
            'MSG-sms': true,
            'MSG-whatsapp': true
        }
    };

    triggerEvent({
        event: SUBSCRIBE_NEWSLETTER,
        clevertapData
    });

    callback.apply(instance, args);
};

export default {
    'Component/NewsletterSubscription/Container': {
        'member-function': {
            onFormSubmitDone
        }
    }
};
