/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import BeforeBody from './BeforeBody.component';

/** @namespace Scandi/Component/BeforeBody/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    absolute_footer: state.ConfigReducer.absolute_footer,
    head_after_body: state.ConfigReducer.head_after_body,
    head_includes: state.ConfigReducer.head_includes
});

/** @namespace Scandi/Component/BeforeBody/Container/BeforeBodyContainer */
export class BeforeBodyContainer extends PureComponent {
    static propTypes = {
        absolute_footer: PropTypes.string,
        head_after_body: PropTypes.string,
        head_includes: PropTypes.string
    };

    static defaultProps = {
        absolute_footer: '',
        head_after_body: '',
        head_includes: ''
    };

    render() {
        return (
            <BeforeBody
              { ...this.props }
            />
        );
    }
}

/** @namespace Scandi/Component/BeforeBody/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(BeforeBodyContainer);
