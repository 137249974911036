/* eslint-disable no-magic-numbers */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
// import PropTypes from 'prop-types';
import React, { createRef, PureComponent } from 'react';
import { connect } from 'react-redux';

import CustomSlider from 'Component/CustomSlider';
import { DeviceType } from 'Type/Device.type';

import VideoSliderSlide from '../VideoSliderSlide';

import './VideoSlider.style';

/** @namespace PageBuilder/Component/VideoSlider/Component/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device
});

/** @namespace PageBuilder/Component/VideoSlider/Component/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

/** @namespace PageBuilder/Component/VideoSlider/Component/VideoSliderComponent */
export class VideoSliderComponent extends PureComponent {
    static propTypes = {
        device: DeviceType.isRequired
    };

    sliderRef = createRef();

    renderSlide(slide) {
        if (!slide || slide.type === 'text') {
            return null;
        }

        return (
            <VideoSliderSlide slide={ slide } />
        );
    }

    render() {
        const {
            elements: {
                props
            },
            device
        } = this.props;

        const { children, ...rest } = props;
        const paddingInline = rest['data-slide-gap'] || '0px';

        const sliderProps = {
            dots: rest['data-show-dots'] && rest['data-show-dots'] === '1',
            arrows: rest['data-show-arrow'] && rest['data-show-arrow'] === '1',
            slidesToScroll: rest['data-slide-to-scroll'] ? rest['data-slide-to-scroll'] * 1 : 1,
            slidesToScrollMobile: rest['data-slide-to-scroll-mobile'] ? rest['data-slide-to-scroll-mobile'] * 1 : 1,
            slidesToShow: rest['data-slides-per-view'] ? rest['data-slides-per-view'] * 1 : 4,
            slidesToShowMobile: rest['data-slides-per-view-mobile'] ? rest['data-slides-per-view-mobile'] : 2,
            autoplay: rest['data-slider-autoplay'] && rest['data-slider-autoplay'] === '1',
            centerMode: device.isMobile
        };

        return (
            <CustomSlider style={ { '--slide-gap': paddingInline } } mix={ { block: 'VideoSlider' } } { ...sliderProps } sliderRef={ this.sliderRef }>
                { children.map(this.renderSlide.bind(this)) }
            </CustomSlider>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoSliderComponent);
