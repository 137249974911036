/* eslint-disable max-len */
import {
    ChevronIcon as SourceChevronIcon
} from 'SourceComponent/ChevronIcon/ChevronIcon.component';

import './ChevronIcon.override.style';

/** @namespace Scandi/Component/ChevronIcon/Component/ChevronIconComponent */
export class ChevronIconComponent extends SourceChevronIcon {
    render() {
        const { direction } = this.props;

        return (
            <svg
              block="ChevronIcon"
              mods={ { direction } }
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
            <g clip-path="url(#clip0_426_3435)">
            <path d="M16.25 7.5L10 13.75L3.75 7.5" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
            <clipPath id="clip0_426_3435">
            <rect width="20" height="20" fill="white" />
            </clipPath>
            </defs>
            </svg>
        );
    }
}

export default ChevronIconComponent;
