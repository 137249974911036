import BrowserDatabase from 'Util/BrowserDatabase';

import {
    SCALPMETERPRODUCTS_ADD,
    SCALPMETERPRODUCTS_CLEAR,
    SCALPMETERPRODUCTS_CONFIGKEY,
    SCALPMETERPRODUCTS_LOAD,
    SCALPMETERPRODUCTS_REMOVE,
    SCALPMETERPRODUCTS_TXNID
} from './ScalpMeterProducts.action';

export const defaultState = {
    isLoading: true,
    products: [],
    inCart: [],
    transaction_id: ''
};

/** @namespace ScalpMeter/Store/ScalpMeterProducts/Reducer/onProductLoads */
export const onProductLoads = (state, payload) => {
    const newState = {
        ...state,
        products: payload,
        isLoading: false
    };

    BrowserDatabase.setItem(newState, SCALPMETERPRODUCTS_CONFIGKEY);
    return newState;
};

/** @namespace ScalpMeter/Store/ScalpMeterProducts/Reducer/onProductsClear */
export const onProductsClear = () => {
    BrowserDatabase.deleteItem(SCALPMETERPRODUCTS_CONFIGKEY);

    return defaultState;
};

/** @namespace ScalpMeter/Store/ScalpMeterProducts/Reducer/onProductRemove */
export const onProductRemove = (state, payload) => {
    const newState = {
        ...state,
        inCart: state.inCart.filter((item) => item.sku !== payload.sku)
    };

    BrowserDatabase.setItem(newState, SCALPMETERPRODUCTS_CONFIGKEY);

    return newState;
};

/** @namespace ScalpMeter/Store/ScalpMeterProducts/Reducer/onProductAdd */
export const onProductAdd = (state, payload) => {
    const newState = {
        ...state,
        inCart: [
            ...state.inCart,
            payload
        ]
    };

    BrowserDatabase.setItem(newState, SCALPMETERPRODUCTS_CONFIGKEY);

    return newState;
};

/** @namespace ScalpMeter/Store/ScalpMeterProducts/Reducer/onUpdateTxnID */
export const onUpdateTxnID = (state, payload) => {
    const newState = {
        ...state,
        transaction_id: payload
    };

    BrowserDatabase.setItem(newState, SCALPMETERPRODUCTS_CONFIGKEY);

    return newState;
};

/** @namespace ScalpMeter/Store/ScalpMeterProducts/Reducer/getInitialState */
export const getInitialState = () => BrowserDatabase.getItem(SCALPMETERPRODUCTS_CONFIGKEY) || defaultState;

/** @namespace ScalpMeter/Store/ScalpMeterProducts/Reducer/ScalpMeterProductsReducer */
export const ScalpMeterProductsReducer = (state = getInitialState(), action) => {
    const { payload } = action;
    switch (action.type) {
    case SCALPMETERPRODUCTS_ADD:
        return onProductAdd(state, payload);
    case SCALPMETERPRODUCTS_REMOVE:
        return onProductRemove(state, payload);
    case SCALPMETERPRODUCTS_CLEAR:
        return onProductsClear();
    case SCALPMETERPRODUCTS_LOAD:
        return onProductLoads(state, payload);
    case SCALPMETERPRODUCTS_TXNID:
        return onUpdateTxnID(state, payload);
    default:
        return state;
    }
};

export default ScalpMeterProductsReducer;
