/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Field } from 'Util/Query';

/** @namespace Sslcommerze/Query/Sslcommerze/Query/SslcommerzeQuery */
export class SslcommerzeQuery {
    getCreateSslcommerzePaymentOrderMutation(input) {
        return new Field('createSslcommerzPaymentOrder')
            .addArgument('input', 'SslcommerzOrderInput!', input)
            .addFieldList(this._getPaymentOrderFields())
            .setAlias('sslCommerzeOrder');
    }

    _getPaymentOrderFields() {
        return [
            'success',
            'url',
            'order_id'
        ];
    }

    validateResponse(order_id) {
        return new Field('validateSslcommerzPayment')
            .addArgument('order_id', 'String!', order_id)
            .addFieldList(this._paymentStatusFields())
            .setAlias('sslcommerzValidationResponse');
    }

    _paymentStatusFields() {
        return [
            'status',
            'message'
        ];
    }
}

export default new SslcommerzeQuery();
