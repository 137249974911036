export const DOTDIGITAL_FORM_SKELETON = [{
    name: 'BaseDotForm',
    type: 'div',
    children: [
        {
            name: 'Wrapper',
            type: 'div',
            children: [
                {
                    name: 'Container',
                    type: 'div',
                    children: [
                        { name: 'Script', type: 'script' }
                    ]
                }
            ]
        }
    ]
}];
