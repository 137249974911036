/* eslint-disable react/jsx-no-bind */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable react/no-unknown-property */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/media-has-caption */

// import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Html from 'Component/Html';
import ProductCardComponent from 'Component/ProductCard';

import VideoSliderProduct from '../VideoSliderProduct';

import './VideoSliderSlide.style';

/** @namespace PageBuilder/Component/VideoSliderSlide/Component/VideoSliderSlideComponent */
export class VideoSliderSlideComponent extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    wrapSlideLink(children) {
        const { product, openPopup } = this.props;

        if (!product) {
            return children;
        }

        return (
            <button
              type="button"
              onClick={ () => openPopup(this.renderModal()) }
            >
                { children }
            </button>
        );
    }

    renderModal() {
        const {
            videoprops,
            sku,
            product,
            device
        } = this.props;

        return (
            <div block="VideoSliderSlide" elem="Modal">
                <video { ...videoprops } height={ device.isMobile ? '450px' : '450px' } />
                <div>
                { sku ? (
                    <ProductCardComponent product={ product }>
                        { product && product.short_description && product.short_description.html ? (
                            <div block="ProductCard" elem="Description">
                                <Html content={ product.short_description.html } />
                            </div>
                        ) : null }
                    </ProductCardComponent>
                ) : null }
                </div>
            </div>
        );
    }

    render() {
        const {
            slide,
            videoprops,
            sku,
            setProduct,
            device
        } = this.props;

        return (
            <div { ...slide.attribs } block="VideoSlider" elem="Slide" key={ videoprops.src } { ...slide.attribs }>
                { this.wrapSlideLink(
                <div block="VideoSlider" elem="SlideInner">
                    <video { ...videoprops } height={ device.isMobile ? '350px' : '450px' } />
                    { sku ? <VideoSliderProduct sku={ sku } setProduct={ setProduct } /> : null }
                </div>
                ) }
            </div>
        );
    }
}

export default VideoSliderSlideComponent;
