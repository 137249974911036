import { SEARCH, triggerEvent } from '../../Gtm.config';

export const makeSearchRequest = async (args, callback, instance) => {
    try {
        const { countryCode } = instance.props;
        await callback.apply(instance, args);
        const { searchCriteria } = instance.props;
        if (searchCriteria) {
            triggerEvent({
                event: SEARCH,
                clevertapData: {
                    Keyword: searchCriteria,
                    'Country Code': countryCode
                }
            });
        }

        return null;
    } catch (error) {
        return null;
    }
};

const mapStateToProps = (args, callback, _instance) => {
    const [state] = args;

    return {
        ...callback(...args),
        countryCode: state.ConfigReducer.code
    };
};

export default {
    'Scandi/Component/SearchOverlay/Container/SearchOverlayContainer': {
        'member-function': {
            makeSearchRequest
        }
    },
    'Scandi/Component/SearchOverlay/Container/mapStateToProps': {
        function: mapStateToProps
    }
};
