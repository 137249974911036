import {
    MODELREDUCER_CLOSE,
    MODELREDUCER_OPEN
} from './ModalReducer.action';

/** @namespace Scandi/Store/ModalReducer/Reducer/getInitialState */
export const getInitialState = () => ({
    isOpen: false,
    content: null
});

/** @namespace Scandi/Store/ModalReducer/Reducer/ModelReducerReducer */
export const ModelReducerReducer = (state = getInitialState(), action) => {
    const { payload } = action;
    switch (action.type) {
    case MODELREDUCER_OPEN:
        return {
            ...state,
            isOpen: true,
            content: payload
        };
    case MODELREDUCER_CLOSE:
        return {
            ...state,
            isOpen: false,
            content: null
        };

    default:
        return state;
    }
};

export default ModelReducerReducer;
