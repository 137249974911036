import OrboReducer from '../store/OrboConfig/OrboConfig.reducer';

const getReducers = (args, callback, instance) => ({
    ...callback.apply(instance, args),
    OrboReducer
});

export default {
    'Scandi/Store/Index/getStaticReducers': {
        function: [
            {
                position: 11,
                implementation: getReducers
            }
        ]
    }
};
