/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
import { lazy } from 'react';
import { Route } from 'react-router-dom';

import { withStoreRegex } from 'Component/Router/Router.component';

export const LoginWithOtpComponent = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "extension-name" */
    '../route/LoginWithOtp'
));

const SWITCH_ITEMS_TYPE = (originalMember) => [
    ...originalMember,
    {
        component: (
            <Route
              path={ withStoreRegex('/otp-login') }
              render={ (props) => <LoginWithOtpComponent { ...props } /> }
            />
        ),
        position: 100
    }
];

export const config = {
    'Scandi/Component/Router/Component/RouterComponent': {
        'member-property': {
            SWITCH_ITEMS_TYPE
        }
    }
};

export default config;
