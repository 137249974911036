export const MODELREDUCER_OPEN = 'MODELREDUCER_OPEN';
export const MODELREDUCER_CLOSE = 'MODELREDUCER_CLOSE';

/** @namespace Scandi/Store/ModalReducer/Action/openModal */
export const openModal = (payload) => ({
    type: MODELREDUCER_OPEN,
    payload
});

/** @namespace Scandi/Store/ModalReducer/Action/closeModal */
export const closeModal = (payload) => ({
    type: MODELREDUCER_CLOSE,
    payload
});
