/* eslint-disable no-magic-numbers */
/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import Slider from 'react-slick';

import Loader from 'Component/Loader';
import ProductCard from 'Component/ProductCard';
import Timer from 'Component/Timer';
import { ProductType } from 'Type/ProductList.type';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './CustomProductsSlider.style';

export function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <button
          className={ className }
          style={ { ...style } }
          onClick={ onClick }
        >
            <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L11 11L1 21" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </button>
    );
}

export function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <button
        className={ className }
        style={ { ...style } }
        onClick={ onClick }
      >
        <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L11 11L1 21" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </button>
    );
}

/** @namespace Scandi/Component/CustomProductsSlider/Component/CustomProductsSliderComponent */
export class CustomProductsSliderComponent extends PureComponent {
    static propTypes = {
        categoryName: PropTypes.string.isRequired,
        isLoading: PropTypes.bool.isRequired,
        products: PropTypes.arrayOf(ProductType).isRequired,
        background: PropTypes.string.isRequired,
        isMobile: PropTypes.bool.isRequired,
        showInSlider: PropTypes.number.isRequired,
        sliderType: PropTypes.string.isRequired,
        endTime: PropTypes.string.isRequired,
        autoPlay: PropTypes.number.isRequired,
        autoPlaySpeed: PropTypes.number.isRequired,
        onTimerFinish: PropTypes.func.isRequired
    };

    selectShowInSlider() {
        return Math.round((window.innerWidth - (window.innerWidth / 20)) / 300);
    }

    renderDesktopSlider() {
        const {
            products = [],
            autoPlaySpeed,
            autoPlay
        } = this.props;

        const slidesToShow = this.selectShowInSlider();
        const finalProducts = products;

        if (products.length <= slidesToShow) {
            const abc = Math.trunc(slidesToShow / products.length);
            // eslint-disable-next-line fp/no-let
            for (let index = 0; index < abc; index++) {
                finalProducts.push(...products);
            }
        }

        // eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
        const settings = {
            className: 'slider variable-width',
            slidesToShow,
            slidesToScroll: 1,
            variableWidth: true,
            dots: false,
            arrows: true,
            infinite: true,
            autoplay: autoPlay,
            autoplaySpeed: autoPlaySpeed,
            lazyLoad: true,
            nextArrow: <SampleNextArrow />
            // prevArrow: <SamplePrevArrow />,
            // responsive: [
            //     {
            //         breakpoint: 1624,
            //         settings: {
            //             slidesToShow: this.selectShowInSlider(6)
            //         }
            //     },
            //     {
            //         breakpoint: 1500,
            //         settings: {
            //             slidesToShow: this.selectShowInSlider(5)
            //         }
            //     },
            //     {
            //         breakpoint: 1222,
            //         settings: {
            //             slidesToShow: this.selectShowInSlider(4)
            //         }
            //     },
            //     {
            //         breakpoint: 922,
            //         settings: {
            //             slidesToShow: this.selectShowInSlider(3)
            //         }
            //     },
            //     {
            //         breakpoint: 811,
            //         settings: {
            //             slidesToShow: this.selectShowInSlider(4)
            //         }
            //     },
            //     {
            //         breakpoint: 614,
            //         settings: {
            //             slidesToShow: this.selectShowInSlider(3)
            //         }
            //     },
            //     {
            //         breakpoint: 500,
            //         settings: {
            //             slidesToShow: this.selectShowInSlider(2)
            //         }
            //     },
            //     {
            //         breakpoint: 360,
            //         settings: {
            //             slidesToShow: 1
            //         }
            //     }
            // ]
        };

        return (
            <div block="CustomProductsSlider" elem="products">
                <Slider
                        // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                  { ...settings }
                >
                        { finalProducts.map((product) => (
                            <ProductCard key={ product.sku } product={ product } />
                        )) }
                </Slider>
            </div>
        );
    }

    renderMobileSlider() {
        const { products } = this.props;

        return (
            <div block="CustomProductsSlider" elem="products">
                <ul>
                    { products.map((product) => (
                        <ProductCard key={ product.sku } product={ product } />
                    )) }
                </ul>
            </div>
        );
    }

    render() {
        const {
            isLoading, products = [], categoryName, background, onTimerFinish, endTime, isMobile
        } = this.props;

        if (isLoading) {
            return (
                <div block="CustomProductsSlider">
                    <Loader />
                </div>
            );
        }
        if (!isLoading && !products.length) {
            return null;
        }

        return (
            <section block="CustomProductsSlider" style={ { background } }>
                <div block="CustomProductsSlider" elem="title">
                    <span>{ categoryName }</span>
                </div>
                { endTime && (
                <div block="CustomProductsSlider" elem="HeaderTimeLeft">
                    <div>Expires In</div>
                    { ' ' }
                    <Timer time={ new Date(endTime) } fullTimer onFinish={ onTimerFinish } />
                </div>
                ) }
                { isMobile ? this.renderMobileSlider() : this.renderDesktopSlider() }
            </section>
        );
    }
}

export default CustomProductsSliderComponent;
