export const RATES = {
    INR_SGD: 0.018,
    BDT_SGD: 0.016,
    PKR_SGD: 0.0081,
    OMR_SGD: 3.49,
    QAR_SGD: 0.37,
    AED_SGD: 0.37,
    SAR_SGD: 0.36,
    BHD_SGD: 3.56,
    KWD_SGD: 4.47,

    INR_AED: 0.049,
    BDT_AED: 0.043,
    PKR_AED: 0.021,
    OMR_AED: 9.55,
    QAR_AED: 1.01,
    AED_AED: 1,
    SAR_AED: 0.98,
    BHD_AED: 9.74,
    KWD_AED: 12.14,

    AED_INR: 20.69,
    BDT_INR: 0.88,
    PKR_INR: 0.41,
    OMR_INR: 197.38,
    QAR_INR: 20.87,
    INR_INR: 1,
    SAR_INR: 20.26,
    BHD_INR: 201.56,
    KWD_INR: 249.84
};
