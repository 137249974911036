import { localDateToIst } from 'Util/helpers';
import { Field } from 'Util/Query';

/** @namespace gaTracking/Query/GaTracking/Query/GaTrackingQuery */
export class GaTrackingQuery {
    getQuery(options) {
        const date = localDateToIst();

        return new Field('gaTracking')
            .addArgument('page_type', 'String!', options.page_type)
            .addArgument('event_data', 'String!', options.event_data)
            .addArgument('log_date', 'String!', date)
            .addFieldList([
                'success',
                'message'
            ]);
    }
}

export default new GaTrackingQuery();
