import BrowserDatabase from 'Util/BrowserDatabase';

import {
    ADD_PAYMENT_INFO,
    ADD_SHIPPING_INFO,
    EVENT_BEGIN_CHECKOUT,
    GTM_DATA,
    SAVE_PAYMENT_AND_PLACE_ORDER,
    triggerEvent,
    UTM_DATA
} from '../../Gtm.config';

const mapStateToProps = (args, callback, _instance) => {
    const [state] = args;

    return {
        ...callback(...args),
        countryCode: state.ConfigReducer.code
    };
};

const state = (originalMember) => ({
    ...originalMember,
    gtmTriggered: false
});

const render = (args, callback, instance) => {
    const {
        totals: {
            quote_currency_code,
            grand_total,
            items,
            coupon_code,
            tax_amount,
            shipping_incl_tax
        },
        countryCode
    } = instance.props;

    if (!items.length) {
        return callback.apply(instance, args);
    }

    const { gtmTriggered } = instance.state;

    if (!gtmTriggered) {
        const itemsData = [];
        // eslint-disable-next-line fp/no-let
        let count = 1;
        const clevertapData = {
            'Country Code': countryCode
        };

        if (items) {
            items.forEach((d) => {
                const {
                    qty,
                    sku,
                    price,
                    product: {
                        name,
                        url,
                        price_range: {
                            minimum_price: {
                                discount: { amount_off = 0, percent_off = 0 } = {}
                            } = {}
                        } = {}
                    }
                } = d;

                if (price > 0) {
                    itemsData.push(
                        {
                            item_id: sku,
                            item_name: name,
                            currency: quote_currency_code,
                            price,
                            quantity: qty
                        }
                    );

                    clevertapData[`Product Name - ${count}`] = name;
                    clevertapData[`Product SKU - ${count}`] = sku;
                    clevertapData[`Product Price - ${count}`] = price;
                    clevertapData[`Product Quantity - ${count}`] = qty;
                    clevertapData[`Product URL - ${count}`] = url;
                    clevertapData[`Product Discount Amount - ${count}`] = amount_off;
                    clevertapData[`Product Discount Percent - ${count}`] = percent_off;
                    count++;
                }
            });
        }

        const utmData = BrowserDatabase.getItem(UTM_DATA);

        triggerEvent({ ecommerce: null });

        triggerEvent({
            event: EVENT_BEGIN_CHECKOUT,
            ecommerce: {
                value: grand_total,
                items: itemsData,
                currency: quote_currency_code,
                coupon: coupon_code,
                shipping: shipping_incl_tax,
                tax: tax_amount
            },
            utm_data: utmData,
            clevertapData
        });

        instance.setState({
            gtmTriggered: true
        });
    }

    return callback.apply(instance, args);
};

const savePaymentMethodAndPlaceOrder = (args, callback, instance) => {
    const { totals } = instance.props;
    // this data will be used to trigger gtm transaction event on checkout success page
    BrowserDatabase.setItem(totals, GTM_DATA);
    const utmData = BrowserDatabase.getItem(UTM_DATA);
    triggerEvent({
        event: SAVE_PAYMENT_AND_PLACE_ORDER,
        utm_data: utmData
    });

    return callback.apply(instance, args);
};

const savePaymentInformation = (args, callback, instance) => {
    const utmData = BrowserDatabase.getItem(UTM_DATA);
    triggerEvent({
        event: ADD_PAYMENT_INFO,
        utm_data: utmData
    });

    return callback.apply(instance, args);
};

export const saveAddressInformation = async (args, callback, instance) => {
    try {
        await callback(...args);
        const [addresses] = args;
        const { shipping_address } = addresses;
        if (shipping_address) {
            const itemsData = [];
            const {
                totals: {
                    quote_currency_code,
                    grand_total,
                    items,
                    coupon_code,
                    tax_amount,
                    shipping_incl_tax
                }
            } = instance.props;

            const clevertapData = {
                city: shipping_address.city,
                country_id: shipping_address.country_id,
                firstname: shipping_address.firstname,
                lastname: shipping_address.lastname,
                postcode: shipping_address.postcode,
                region: shipping_address.region,
                region_id: shipping_address.region_id,
                // eslint-disable-next-line max-len
                street: typeof shipping_address.street === 'object' ? shipping_address.street.join(', ') : shipping_address.street,
                telephone: shipping_address.telephone
            };

            // eslint-disable-next-line fp/no-let
            let count = 1;
            items.forEach((item) => {
                const { product } = item;
                const {
                    price_range: {
                        minimum_price: {
                            discount: { amount_off = 0, percent_off = 0 } = {}
                        } = {}
                    } = {}
                } = product;

                clevertapData[`Product Name - ${count}`] = product.name;
                clevertapData[`Product SKU - ${count}`] = product.sku;
                clevertapData[`Product Price - ${count}`] = item.price;
                clevertapData[`Product Quantity - ${count}`] = item.qty;
                clevertapData[`Product URL - ${count}`] = product.url;
                clevertapData[`Product Discount Amount - ${count}`] = amount_off;
                clevertapData[`Product Discount Percent - ${count}`] = percent_off;
                count++;

                itemsData.push(
                    {
                        item_id: product.sku,
                        item_name: name,
                        currency: quote_currency_code,
                        price: item.price,
                        quantity: item.qty
                    }
                );
            });

            const utmData = BrowserDatabase.getItem(UTM_DATA);

            triggerEvent({ ecommerce: null });

            triggerEvent({
                event: ADD_SHIPPING_INFO,
                ecommerce: {
                    value: grand_total,
                    items: itemsData,
                    currency: quote_currency_code,
                    coupon: coupon_code,
                    shipping: shipping_incl_tax,
                    tax: tax_amount
                },
                utm_data: utmData,
                clevertapData
            });
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log('saveAddressInformation - error', error);
    }
};

export default {
    'Scandi/Route/Checkout/Container/CheckoutContainer': {
        'member-property': {
            state
        },
        'member-function': {
            render,
            savePaymentInformation,
            savePaymentMethodAndPlaceOrder,
            saveAddressInformation
        }
    },
    'Scandi/Route/Checkout/Container/mapStateToProps': {
        function: mapStateToProps
    }
};
