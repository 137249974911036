import BrowserDatabase from 'Util/BrowserDatabase';
import getStore from 'Util/Store';

import {
    FETCH_PROFILE,
    LOGIN,
    REGISTRATION_COMPLETE,
    triggerEvent,
    UTM_DATA
} from '../../Gtm.config';

export const signIn = async (args, callback, instance) => {
    const isSignedIn = await callback.apply(instance, args);
    if (isSignedIn) {
        const [customer] = args;
        const store = getStore();
        const { getState } = store;
        const reduxState = getState();
        const countryCode = reduxState.ConfigReducer.code;
        const clevertapData = {
            Site: {
                Email: customer.email,
                'MSG-email': true,
                'MSG-push': true,
                'MSG-sms': true,
                'MSG-whatsapp': true,
                'Country Code': countryCode
            }
        };

        triggerEvent({
            event: LOGIN,
            clevertapData
        });
    }

    return isSignedIn;
};

export const requestCustomerDataExecutePostThen = (args, callback) => {
    const utmData = BrowserDatabase.getItem(UTM_DATA);
    const [{ customer }] = args;

    const clevertapData = {
        Site: {
            Name: `${customer.firstname} ${customer.lastname}`
        }
    };

    triggerEvent({
        event: FETCH_PROFILE,
        utm_data: utmData,
        clevertapData
    });

    callback(...args);
};

const createAccount = (args, callback, instance) => {
    const utmData = BrowserDatabase.getItem(UTM_DATA);
    const [data] = args;
    const { customer } = data;

    const clevertapData = {
        'Customer Name': `${ customer.firstname } ${ customer.lastname }`,
        'Customer Email': customer.email
    };

    triggerEvent({
        event: REGISTRATION_COMPLETE,
        utm_data: utmData,
        clevertapData
    });

    return callback.apply(instance, args);
};

export default {
    'Store/MyAccount/Dispatcher': {
        'member-function': {
            signIn,
            createAccount
        }
    },
    'Store/MyAccount/Dispatcher/MyAccountDispatcher/requestCustomerData/executePost/then': {
        function: requestCustomerDataExecutePostThen
    }
};
