/* eslint-disable react/no-unknown-property */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Image from 'Component/Image';
import Loader from 'Component/Loader';
import ProductPrice from 'Component/ProductPrice';
import TextPlaceholder from 'Component/TextPlaceholder';
import { ProductType } from 'Type/ProductList.type';
import fromCache from 'Util/Cache/Cache';
import { getPrice, getSmallImage } from 'Util/Product/Extract';

import './VideoSliderProduct.style';

/** @namespace PageBuilder/Component/VideoSliderProduct/Component/VideoSliderProductComponent */
export class VideoSliderProductComponent extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired,
        isReady: PropTypes.bool.isRequired
    };

    renderImage() {
        const { product, product: { id, name } } = this.props;
        const thumbnail = getSmallImage(product);

        return (
            <div block="VideoSliderProduct" elem="Image">
                <Image
                  src={ thumbnail }
                  alt={ name }
                  ratio="custom"
                  isPlaceholder={ !id }
                />
            </div>
        );
    }

    renderName() {
        const { product: { name } } = this.props;

        return (
            <p block="VideoSliderProduct" elem="Name">
                <TextPlaceholder content={ name } length="medium" />
            </p>
        );
    }

    renderPrice() {
        const {
            product: {
                price_range: priceRange = {},
                dynamic_price: dynamicPrice = false,
                type_id: type,
                options = [],
                price_tiers: priceTiers
            }
        } = this.props;
        const productPrice = fromCache(getPrice, [priceRange, dynamicPrice, {}, type, options]);

        if (!productPrice) {
            return null;
        }

        return (
            <div
              block="VideoSliderProduct"
              elem="PriceWrapper"
            >
                <ProductPrice
                  meta
                  price={ productPrice }
                  priceType={ type }
                  tierPrices={ priceTiers }
                  isPreview
                />
            </div>
        );
    }

    render() {
        const {
            product,
            isReady
        } = this.props;

        if (!isReady) {
            return <Loader isLoading />;
        }

        if (isReady && !product) {
            return null;
        }

        return (
            <div block="VideoSliderProduct">
                { this.renderImage() }
                { this.renderName() }
                { this.renderPrice() }
            </div>
        );
    }
}

export default VideoSliderProductComponent;
