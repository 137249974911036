import PropTypes from 'prop-types';

const mapStateToProps = (args, callback, _instance) => {
    const [state] = args;

    return {
        ...callback(...args),
        countryCode: state.ConfigReducer.code
    };
};

const propTypes = (originalMember) => ({
    ...originalMember,
    countryCode: PropTypes.string.isRequired
});

const state = (originalMember) => ({
    ...originalMember,
    gtmTriggered: false
});

export class CategoryPageContainer {
    containerFunctions = (originalMember, instance) => ({
        ...originalMember,
        triggerGtm: this.triggerGtm.bind(instance)
    });

    triggerGtm() {
        this.setState({
            gtmTriggered: true
        });
    }
}

const containerProps = (args, callback, instance) => {
    const { gtmTriggered } = instance.state;
    const { countryCode } = instance.props;

    return {
        ...callback(...args),
        gtmTriggered,
        countryCode
    };
};

const { containerFunctions } = new CategoryPageContainer();

export default {
    'Scandi/Route/CategoryPage/Container/CategoryPageContainer': {
        'static-member': {
            propTypes
        },
        'member-property': {
            state,
            containerFunctions
        },
        'member-function': {
            containerProps
        }
    },
    'Scandi/Route/CategoryPage/Container/mapStateToProps': {
        function: mapStateToProps
    }
};
