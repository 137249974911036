/* eslint-disable react/no-unknown-property */
import PropTypes from 'prop-types';

import Loader from 'Component/Loader';

import { POSTPAY_PAY_LATER, POSTPAY_PROCESSING_STEP } from './Checkout.config';

import './Checkout.style.scss';

const propTypes = (originalMember) => ({
    ...originalMember,
    postpay: PropTypes.shape({
        isLoading: PropTypes.bool.isRequired,
        isPostpayPayment: PropTypes.bool.isRequired,
        isError: PropTypes.bool.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        cart: PropTypes.any,
        increment_id: PropTypes.string,
        errorMessage: PropTypes.string.isRequired
    }).isRequired,
    orderID: PropTypes.string.isRequired
});

function renderPostpayStep() {
    const { postpay: { isLoading, isError, errorMessage } = {} } = this.props;

    if (isLoading) {
        return <Loader isLoading />;
    }

    if (!isError) {
        return this.renderDetailsStep();
    }

    return (
        <div block="Checkout" elem="Content">{ errorMessage }</div>
    );
}

const stepMap = (member, instance) => ({
    ...member,
    [POSTPAY_PROCESSING_STEP]: {
        title: __('Loading'),
        url: `/${POSTPAY_PAY_LATER}`,
        render: renderPostpayStep.bind(instance),
        areTotalsVisible: false
    }
});

const renderTitle = (args, callback, instance) => {
    const { checkoutStep, postpay: { isLoading, isError } = {} } = instance.props;

    if (checkoutStep !== POSTPAY_PROCESSING_STEP) {
        return callback.apply(instance, args);
    }

    if (isLoading) {
        return (
            <div block="Checkout" elem="Header">
                <div block="Checkout" elem="Title">{ __('Loading, please wait') }</div>
            </div>
        );
    }

    if (!isError) {
        return null;
    }

    const message = __('There was an error processing your order.');

    return (
        <div block="Checkout" elem="Header">
            <div block="Checkout" elem="Title">{ message }</div>
        </div>
    );
};

const renderGuestForm = (args, callback, instance) => {
    const { checkoutStep } = instance.props;

    if (checkoutStep !== POSTPAY_PROCESSING_STEP) {
        return callback.apply(instance, args);
    }

    return null;
};

export default {
    'Scandi/Route/Checkout/Component/CheckoutComponent': {
        'member-property': {
            stepMap
        },
        'static-member': {
            propTypes
        },
        'member-function': {
            renderTitle,
            renderGuestForm,
            renderPromo: renderGuestForm
        }
    }
};
