import { CLOSE_TRY_NOW, OPEN_TRY_NOW } from './OrboConfig.action';

/** @namespace Orbo/Store/OrboConfig/Reducer/getInitialState */
export const getInitialState = () => ({
    isOpen: false
});

/** @namespace Orbo/Store/OrboConfig/Reducer/OrboConfigReducer */
export const OrboConfigReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case OPEN_TRY_NOW:
        return {
            ...state,
            isOpen: true
        };

    case CLOSE_TRY_NOW:
        return {
            ...state,
            isOpen: false
        };

    default:
        return state;
    }
};

export default OrboConfigReducer;
