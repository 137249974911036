export const SCALPMETERPRODUCTS_ADD = 'SCALPMETERPRODUCTS_ADD';
export const SCALPMETERPRODUCTS_REMOVE = 'SCALPMETERPRODUCTS_REMOVE';
export const SCALPMETERPRODUCTS_CLEAR = 'SCALPMETERPRODUCTS_CLEAR';
export const SCALPMETERPRODUCTS_LOAD = 'SCALPMETERPRODUCTS_LOAD';
export const SCALPMETERPRODUCTS_TXNID = 'SCALPMETERPRODUCTS_TXNID';

export const SCALPMETERPRODUCTS_CONFIGKEY = 'SCALPMETERPRODUCTS_CONFIGKEY';

/** @namespace ScalpMeter/Store/ScalpMeterProducts/Action/addProduct */
export const addProduct = (payload) => ({
    type: SCALPMETERPRODUCTS_ADD,
    payload
});

/** @namespace ScalpMeter/Store/ScalpMeterProducts/Action/removeProduct */
export const removeProduct = (payload) => ({
    type: SCALPMETERPRODUCTS_REMOVE,
    payload
});

/** @namespace ScalpMeter/Store/ScalpMeterProducts/Action/clearProducts */
export const clearProducts = () => ({ type: SCALPMETERPRODUCTS_CLEAR });

/** @namespace ScalpMeter/Store/ScalpMeterProducts/Action/loadProducts */
export const loadProducts = (payload) => ({ type: SCALPMETERPRODUCTS_LOAD, payload });

/** @namespace ScalpMeter/Store/ScalpMeterProducts/Action/updateTransactionId */
export const updateTransactionId = (payload) => ({ type: SCALPMETERPRODUCTS_TXNID, payload });
