/* eslint-disable */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import ProductListQuery from 'Query/ProductList.query';
import { getIndexedProducts } from 'Util/Product';
import { fetchGetQuery } from 'Util/Request';

import ProductsVideo from './ProductsVideo.component';

/** @namespace Scandi/Component/ProductsVideo/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isMobile: state.ConfigReducer.device.isMobile
});

/** @namespace Scandi/Component/ProductsVideo/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
});

/** @namespace Scandi/Component/ProductsVideo/Container/ProductsVideoContainer */
export class ProductsVideoContainer extends PureComponent {
    static propTypes = {
        isMobile: PropTypes.bool.isRequired,
        productsSKU: PropTypes.string.isRequired,
        paddingInline: PropTypes.string.isRequired,
        sliderProps: PropTypes.object.isRequired
    };

    state = {
        isLoading: true,
        productsobject: [],
        products: [],
        slides: 0
    };

    componentDidMount() {
        const {
            isMobile,
            sliderProps
        } = this.props;
        this.setState({ isLoading: true });
        this.getProducts();
        if (isMobile) {
            this.setState({ slides: parseInt(sliderProps.slidesToShowMobile) });
        } else {
            this.setState({ slides: parseInt(sliderProps.slidesToShow) });
        }
    }

    containerProps() {
        const {
            isMobile,
            productsSKU,
            paddingInline,
            sliderProps
        } = this.props;
        const {
            isLoading,
            products,
            productsobject,
            slides
        } = this.state;

        return ({
            isMobile,
            productsSKU,
            paddingInline,
            sliderProps,
            isLoading,
            products,
            productsobject,
            slides
        }
        );
    }

    async getProducts() {
        const { productsSKU } = this.props;
        const { isLoading } = this.state;
        // console.log('attributes', productsSKU);
        const abc = JSON.parse(productsSKU);
        this.setState({ productsobject: abc });
        const productSKUs = Object.keys(abc).map((val) => val);
        // console.log('attributes', abc, productSKUs);
        try {
            if (productSKUs && isLoading) {
                const query = ProductListQuery.getQuery({
                    args: {
                        filter: {
                            productsSkuArray: productSKUs
                        }
                    },
                    isPlp: true
                });

                const data = await fetchGetQuery(query);
                const { products: { items = [] } = {} } = data;
                const indexed = getIndexedProducts(items);

                this.setState({
                    isLoading: false,
                    products: indexed
                });
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log('error, ', error);
        }
    }

    render() {
        return (
            <ProductsVideo
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductsVideoContainer);
