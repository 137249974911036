import BrowserDatabase from 'Util/BrowserDatabase';

import { triggerEvent, UTM_DATA, VIEW_ITEM } from '../../Gtm.config';

const state = (originalMember) => ({
    ...originalMember,
    gtmTriggered: false
});

const render = (args, callback, instance) => {
    const { activeProduct } = instance.state;
    const {
        product,
        countryCode
    } = instance.props;

    const currentProduct = activeProduct || product;

    const { gtmTriggered } = instance.state;

    if (Object.keys(currentProduct).length && !gtmTriggered) {
        const {
            sku,
            name,
            url,
            price_range: {
                minimum_price: {
                    final_price: {
                        currency, value
                    } = {},
                    regular_price: {
                        currency: regular_currency,
                        value: regular_value
                    } = {},
                    discount: { amount_off = 0, percent_off = 0 } = {}
                } = {}
            } = {}
        } = currentProduct;

        const utmData = BrowserDatabase.getItem(UTM_DATA);

        const clevertapData = {
            'Product name': name,
            'Product ID': sku,
            'Product URL': url,
            'Selling Price': `${currency} ${value}`,
            'Regular Price': `${regular_currency} ${regular_value}`,
            'Country Code': countryCode,
            'Discount Percent': percent_off,
            'Discount Amount': amount_off
        };

        triggerEvent({ ecommerce: null });

        triggerEvent({
            event: VIEW_ITEM,
            ecommerce: {
                currency,
                value,
                items: [{
                    item_name: name,
                    item_id: sku,
                    price: value,
                    currency,
                    quantity: 1
                }]
            },
            utm_data: utmData,
            clevertapData
        });

        instance.setState({
            gtmTriggered: true
        });
    }

    return callback.apply(instance, args);
};

export default {
    'Scandi/Route/ProductPage/Container/ProductPageContainer': {
        'member-function': {
            render
        },
        'member-property': {
            state
        }
    }
};
