import CheckoutQuery from 'Query/Checkout.query';
import { PAYMENT_TOTALS, SUCCESS_DATA } from 'Route/Checkout/Checkout.config';
import { isSignedIn } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import { getGuestQuoteId } from 'Util/Cart';
import { fetchMutation } from 'Util/Request';

import { SSLCOMMERZE } from '../../Sslcommerze.config';
import SslcommerzeQuery from '../query/Sslcommerze.query';

const savePaymentMethodAndPlaceOrder = async (args, callback, instance) => {
    const [paymentInformation] = args;

    const { paymentMethod: { code, additional_data, purchase_order_number } } = paymentInformation;
    const guest_cart_id = !isSignedIn() ? getGuestQuoteId() : '';

    if (SSLCOMMERZE !== code) {
        await callback(...args);
        return;
    }

    try {
        await fetchMutation(CheckoutQuery.getSetPaymentMethodOnCartMutation({
            guest_cart_id,
            payment_method: {
                code,
                additional_data,
                purchase_order_number
            }
        }));

        const { sslCommerzeOrder: { success, url, order_id } } = await fetchMutation(
            SslcommerzeQuery.getCreateSslcommerzePaymentOrderMutation(
                !isSignedIn() ? { guest_cart_id } : {}
            )
        );

        if (!success) {
            throw new Error('Something went wrong');
        }

        const { grand_total, items, quote_currency_code } = BrowserDatabase.getItem(PAYMENT_TOTALS);
        const paymentMethodTitle = __('Paid with Card');
        const successData = {
            grand_total,
            items,
            currency: quote_currency_code,
            paymentMethodTitle
        };

        BrowserDatabase.setItem({ orderID: order_id, successData }, SUCCESS_DATA);
        window.location.href = url;
    } catch (e) {
        instance._handleError(e);
    }
};

export default {
    'Scandi/Route/Checkout/Container/CheckoutContainer': {
        'member-function': {
            savePaymentMethodAndPlaceOrder
        }
    }
};
