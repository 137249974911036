/* eslint-disable no-magic-numbers */
import moment from 'moment-timezone';

import { PRODUCT_TYPE } from 'Component/Product/Product.config';
import { STOCK_TYPE } from 'Component/Product/Stock.config';
import { IN_STOCK } from 'Component/ProductCard/ProductCard.config';

import { getProductInStock } from './Product/Extract';

/** @namespace Scandi/Util/Helpers/getCountryCode */
export const getCountryCode = (key) => {
    switch (key) {
    case 'in':
        return '+91';
    case 'bd':
        return '+880';
    case 'sa':
        return '+966';
    case 'ae':
        return '+971';
    case 'kw':
        return '+965';
    case 'pk':
        return '+92';
    case 'qa':
        return '+974';
    case 'bh':
        return '+973';
    case 'om':
        return '+968';
    case 'ni':
        return '+505';
    default:
        return '+91';
    }
};

/** @namespace Scandi/Util/Helpers/getCountryCodeLength */
export const getCountryCodeLength = (key) => {
    switch (key) {
    case 'in':
        return {
            max_length: 11,
            min_length: 9
        };
    case 'bd':
        return {
            max_length: 12,
            min_length: 12
        };
    case 'sa':
        return {
            max_length: 11,
            min_length: 11
        };
    case 'ae':
        return {
            max_length: 11,
            min_length: 11
        };
    case 'kw':
        return {
            max_length: 10,
            min_length: 10
        };
    case 'pk':
        return {
            max_length: 10,
            min_length: 10
        };
    case 'qa':
        return {
            max_length: 10,
            min_length: 10
        };
    case 'bh':
        return {
            max_length: 13,
            min_length: 12
        };
    case 'om':
        return {
            max_length: 7,
            min_length: 7
        };
    case 'ni':
        return {
            max_length: 10,
            min_length: 10
        };
    default:
        return {
            max_length: 10,
            min_length: 10
        };
    }
};
/** @namespace Scandi/Util/Helpers/trimTrailingSlash */
export const trimTrailingSlash = (string = '') => {
    if (string.endsWith('/')) {
        return string.slice(0, -1);
    }

    return string;
};

/** @namespace Scandi/Util/Helpers/stripHtml */
export const stripHtml = (html) => {
    const temporalDivElement = document.createElement('div');
    temporalDivElement.innerHTML = html;
    return temporalDivElement.textContent || temporalDivElement.innerText || '';
};

/** @namespace Scandi/Util/Helpers/makeExcerpt */
// eslint-disable-next-line no-magic-numbers, max-len
export const makeExcerpt = (string = '', length = 40) => (string.length > length ? string.substring(0, length) : string);

/** @namespace Scandi/Util/Helpers/getPathFromUrl */
export const getPathFromUrl = (url = '') => url.split(/[?#]/)[0];

/** @namespace Scandi/Util/Helpers/localDateToIst */
export const localDateToIst = () => {
    const localdate = moment(new Date());
    const formatedDate = localdate.tz('Asia/Kolkata');
    return formatedDate.format('MMMM Do YYYY, h:mm:ss a');
};

/** @namespace Scandi/Util/Helpers/getDefaultCustomizableOptions */
export const getDefaultCustomizableOptions = (product) => {
    const { items = [] } = product;

    if (items.length === 0) {
        return {};
    }

    const requiredOptions = items.reduce((acc, { option_id, required }) => {
        if (required) {
            acc.push(option_id);
        }

        return acc;
    }, []);

    const values = items.reduce((all, { option_id, required, options }) => {
        if (!required) {
            return all;
        }
        const option = options.find((item) => (item.is_default && getProductInStock(item.product)) || getProductInStock(item.product));

        if (!option) {
            return all;
        }

        const { product } = option;

        const {
            type_id: type,
            salable_qty,
            price_range: {
                maximum_price: {
                    default_final_price: {
                        currency = 'AED'
                    } = {}
                } = {}
            } = {}
        } = product;

        // eslint-disable-next-line no-magic-numbers, fp/no-let
        let isOos = salable_qty <= 0 && type === PRODUCT_TYPE.simple;

        if (currency === 'KWD') {
            isOos = (salable_qty + 5) <= 10 && type === PRODUCT_TYPE.simple;
        }

        if (!product || product.stock_status !== 'IN_STOCK' || isOos) {
            return all;
        }

        return [...all, {
            id: option_id,
            quantity: 1,
            value: [
                option.uid
            ]
        }];
    }, []);

    return {
        requiredOptions,
        productOptions: values
    };
};

/** @namespace Scandi/Util/Helpers/isBundleProductOutOfStock */
export const isBundleProductOutOfStock = (product, options = null) => {
    const { items } = product;

    if (!items || items.length === 0) {
        return true;
    }

    if (!options) {
        const { requiredOptions, productOptions } = getDefaultCustomizableOptions(product);

        if (requiredOptions.length !== productOptions.length) {
            return true;
        }

        const outOfStockItems = productOptions.filter(({ id, value }) => {
            const item = items.find((i) => i.option_id === id);
            if (!item) {
                return true;
            }

            const options = item.options.filter((o) => value.includes(o.uid));
            if (!options) {
                return true;
            }

            return options.some(({ product }) => {
                const {
                    type_id: type,
                    salable_qty,
                    price_range: {
                        maximum_price: {
                            default_final_price: {
                                currency = 'AED'
                            } = {}
                        } = {}
                    } = {}
                } = product;

                // eslint-disable-next-line no-magic-numbers
                const isOos = currency === 'KWD' && (salable_qty + 5) <= 10 && type === PRODUCT_TYPE.simple;

                return product.stock_status !== IN_STOCK || product.salable_qty <= 0 || isOos;
            });
        });

        return outOfStockItems.length;
    }

    if (!Object.keys(options).length) {
        return true;
    }

    const { productOptions, requiredOptions = [] } = options;

    if (!productOptions) {
        return true;
    }

    return requiredOptions.length !== productOptions.length;
};

/** @namespace Scandi/Util/Helpers/formatImageName */
export const formatImageName = (imageName) => {
    // eslint-disable-next-line fp/no-let
    let formattedName = imageName.replace(/\.[^/.]+$/, ''); // remove extension
    formattedName = formattedName.replace(/[^a-zA-Z0-9\s]/g, ' '); // replace all special characters with space
    formattedName = formattedName.replace(/\b\w/g, (l) => l.toUpperCase()); // capitalizing the first letter of each word
    return formattedName;
};

/** @namespace Scandi/Util/Helpers/getBundleProductSku */
export const getBundleProductSku = (product, options, type = STOCK_TYPE.IN_STOCK) => {
    const { items = [] } = product;

    if (items.length === 0) {
        return '';
    }

    const ids = items.reduce((all, item) => {
        const option = item.options.find(
            (o) => {
                const {
                    type_id,
                    salable_qty,
                    price_range: {
                        maximum_price: {
                            default_final_price: {
                                currency = 'AED'
                            } = {}
                        } = {}
                    } = {}
                } = o.product;

                // eslint-disable-next-line no-magic-numbers
                const isOos = currency === 'KWD' && (salable_qty + 5) <= 10 && type_id === PRODUCT_TYPE.simple;

                return options.includes(o.uid)
                && (
                    type === STOCK_TYPE.OUT_OF_STOCK ? (
                        type === o.product.stock_status || o.product.salable_qty <= 0 || isOos
                    ) : type === o.product.stock_status
                );
            }
        );

        if (!option) {
            return all;
        }

        const returnData = [...all, option.product.id];

        return returnData;
    }, []);

    return ids.join('-');
};
/** @namespace Scandi/Util/Helpers/getRandomNumberInRange */
export const getRandomNumberInRange = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

/** @namespace Scandi/Util/Helpers/isStagingSite */
export const isStagingSite = () => {
    const url = window.location.href;
    const stagingDomains = ['dev1m24', 'new', 'devm244'];
    // eslint-disable-next-line fp/no-let
    for (let i = 0; i < stagingDomains.length; i++) {
        const string = stagingDomains[i];
        if (url.includes(string)) {
            return true;
        }
    }

    return false;
};

/** @namespace Scandi/Util/Helpers/formatCurrency */
export const formatCurrency = (amount, currency = 'INR', locale = 'en-US') => new Intl.NumberFormat(locale, {
    style: 'currency',
    currency
}).format(amount);
