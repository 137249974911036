import { Field } from 'Util/Query';

/** @namespace UtmTracker/Query/UtmTracker/Query/UtmTrackerQuery */
export class UtmTrackerQuery {
    getQuery(args) {
        return new Field('updateUtmData')
            .addArgument('order_id', 'String!', args.order_id)
            .addArgument('utm_source', 'String!', args.utm_source)
            .addArgument('utm_medium', 'String!', args.utm_medium)
            .addArgument('utm_campaign', 'String!', args.utm_campaign)
            .addArgument('rewards_skus', 'String!', args.rewards_skus)
            .addFieldList([
                'success',
                'message'
            ]);
    }
}

export default new UtmTrackerQuery();
