/* eslint-disable react/boolean-prop-naming */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import Form from 'Component/Form';
import Timer from 'Component/Timer';
import { SignInStateType } from 'Type/Account.type';
import { VALIDATION_INPUT_TYPE } from 'Util/Validator/Config';

// import { countryCodes } from './MyAccountSignInWithOtp.config';
import './MyAccountSignInWithOtp.style';

/** @namespace OtpLogin/Component/MyAccountSignInWithOtp/Component/MyAccountSignInWithOtpComponent */
export class MyAccountSignInWithOtpComponent extends PureComponent {
    static propTypes = {
        onSignInSuccess: PropTypes.func.isRequired,
        onFormError: PropTypes.func.isRequired,
        handleCreateAccount: PropTypes.func.isRequired,
        isCheckout: PropTypes.bool.isRequired,
        state: SignInStateType.isRequired,
        handleSignIn: PropTypes.func.isRequired,
        onRequestOtp: PropTypes.func.isRequired,
        isOtpSent: PropTypes.bool.isRequired,
        is_customer: PropTypes.bool.isRequired,
        onTimerExpire: PropTypes.func.isRequired,
        onClickResendOtp: PropTypes.func.isRequired,
        showResend: PropTypes.bool.isRequired,
        otp_expire: PropTypes.string.isRequired,
        phone_number: PropTypes.string.isRequired,
        minimunPasswordLength: PropTypes.number.isRequired
        // countryCode: PropTypes.string.isRequired
    };

    static defaultProps = {
        // handleEmailInput: () => {}
    };

    renderSignInForm() {
        const {
            onRequestOtp,
            onFormError,
            phone_number,
            isCheckout
            // countryCode
        } = this.props;
        // const country = countryCodes[countryCode] || '+971';

        return (
            <Form
              key="sign-in"
              onSubmit={ onRequestOtp }
              onError={ onFormError }
            >
                <div mix={ { block: 'LoginWithOtpForm' } }>
                    <Field
                      label=""
                      type={ FIELD_TYPE.tel }
                      attr={ {
                          id: 'phone_number',
                          name: 'phone_number',
                          placeholder: 'Mobile Number',
                          defaultValue: phone_number,
                          autocomplete: isCheckout ? 'off' : 'phone_number'
                      } }
                      validateOn={ ['onChange'] }
                      validationRule={ {
                          isRequired: true,
                          inputType: VALIDATION_INPUT_TYPE.phone
                      } }
                      addRequiredTag
                    />
                </div>
                <div block="MyAccountOverlay" elem="Buttons">
                    <button block="Button">{ __('Send OTP') }</button>
                </div>
            </Form>
        );
    }

    renderAdditionalField() {
        const {
            isCheckout,
            handleCreateAccount,
            state,
            handleSignIn,
            isOtpSent,
            showResend,
            onTimerExpire,
            otp_expire,
            onClickResendOtp
        } = this.props;

        if (isCheckout) {
            return null;
        }

        return (
            <article block="MyAccountOverlay" elem="Additional" mods={ { state } }>
                { isOtpSent ? (
                    <section>
                        { showResend ? (
                            <button
                              block="Button"
                              mods={ { likeLink: true } }
                              onClick={ onClickResendOtp }
                              type="button"
                            >
                                { __('Resend Otp') }
                            </button>
                        ) : (
                            <Timer time={ otp_expire } onFinish={ onTimerExpire } />
                        ) }
                    </section>
                ) : (
                    <>
                        <section>
                            <button
                              block="Button"
                              mods={ { likeLink: true } }
                              onClick={ handleSignIn }
                              type="button"
                            >
                                { __('Login with email?') }
                            </button>
                        </section>
                        <section block="MyAccountOverlay" elem="AdditionalNewUser">
                            <h4 id="forgot-password-label">{ __('New user?') }</h4>
                            <button
                              block="Link"
                              onClick={ handleCreateAccount }
                              type="button"
                            >
                                { __('Register') }
                            </button>
                        </section>
                    </>
                ) }
            </article>
        );
    }

    renderVerifyOtp() {
        const {
            onFormError,
            onSignInSuccess,
            is_customer,
            minimunPasswordLength
        } = this.props;

        return (
            <Form
              key="verifyOtp"
              onSubmit={ onSignInSuccess }
              onError={ onFormError }
            >
                <Field
                  label={ __('Enter OTP sent to your mobile number') }
                  type={ FIELD_TYPE.text }
                  attr={ {
                      id: 'otp',
                      name: 'otp',
                      placeholder: 'Enter OTP',
                      defaultValue: '',
                      autocomplete: 'off'
                  } }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      isRequired: true
                  } }
                  addRequiredTag
                />
                { !is_customer ? (
                    <>
                    <Field
                      type={ FIELD_TYPE.text }
                      label={ __('First Name') }
                      attr={ {
                          id: 'firstname',
                          name: 'firstname',
                          defaultValue: '',
                          placeholder: __('Your first name'),
                          autocomplete: 'given-name'
                      } }
                      validateOn={ ['onChange'] }
                      validationRule={ {
                          inputType: VALIDATION_INPUT_TYPE.alphaSpace,
                          isRequired: true
                      } }
                      addRequiredTag
                    />
                        <Field
                          type={ FIELD_TYPE.text }
                          label={ __('Last Name') }
                          attr={ {
                              id: 'lastname',
                              name: 'lastname',
                              defaultValue: '',
                              placeholder: __('Your last name'),
                              autocomplete: 'family-name'
                          } }
                          validateOn={ ['onChange'] }
                          validationRule={ {
                              inputType: VALIDATION_INPUT_TYPE.alphaSpace,
                              isRequired: true
                          } }
                          addRequiredTag
                        />
                        <Field
                          type={ FIELD_TYPE.email }
                          label={ __('Email') }
                          attr={ {
                              id: 'email',
                              name: 'email',
                              defaultValue: '',
                              placeholder: __('Your email name'),
                              autocomplete: 'email'
                          } }
                          validateOn={ ['onChange'] }
                          validationRule={ {
                              isRequired: true,
                              inputType: VALIDATION_INPUT_TYPE.email
                          } }
                          addRequiredTag
                        />
                    <Field
                      type={ FIELD_TYPE.password }
                      label={ __('Password') }
                      attr={ {
                          id: 'password',
                          name: 'password',
                          placeholder: __('Enter your password'),
                          autocomplete: 'new-password'
                      } }
                      validateOn={ ['onChange'] }
                      validationRule={ {
                          isRequired: true,
                          inputType: VALIDATION_INPUT_TYPE.password,
                          match: (value) => {
                              const email = document.getElementById('email');
                              return value && email.value !== value;
                          },
                          customErrorMessages: {
                              onMatchFail: __('Passwords can\'t be the same as email!')
                          },
                          range: {
                              min: minimunPasswordLength
                          }
                      } }
                      addRequiredTag
                    />
                    </>
                ) : null }
                <div block="MyAccountOverlay" elem="SignInButton">
                    <button block="Button">{ __('Verify') }</button>
                </div>
            </Form>
        );
    }

    render() {
        const { isOtpSent } = this.props;

        return (
            <>
                { isOtpSent ? this.renderVerifyOtp() : this.renderSignInForm() }
                { this.renderAdditionalField() }
            </>
        );
    }
}

export default MyAccountSignInWithOtpComponent;
