import PropTypes from 'prop-types';

import { CUSTOMER_ACCOUNT, CUSTOMER_SUB_ACCOUNT } from 'Component/Header/Header.config';
import {
    CUSTOMER_ACCOUNT_OVERLAY_KEY,
    STATE_FORGOT_PASSWORD,
    STATE_LOGGED_IN
} from 'Component/MyAccountOverlay/MyAccountOverlay.config';
import {
    ACCOUNT_URL
} from 'Route/MyAccount/MyAccount.config';
import { isSignedIn } from 'Util/Auth';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

import { STATE_SIGNIN_WITH_OTP } from '../component/MyAccountSignInWithOtp/MyAccountSignInWithOtp.config';

const propTypes = (originalMember) => ({
    ...originalMember,
    device: PropTypes.object.isRequired,
    countryCode: PropTypes.string.isRequired
});

const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(...args),
        device: state.ConfigReducer.device,
        countryCode: state.ConfigReducer.code
    };
};

export const redirectOrGetState = (args, callback, instance) => {
    const returnData = callback.apply(instance, args);

    const { countryCode } = instance.props;

    if (!['sa', 'ae'].includes(countryCode)) {
        return returnData;
    }

    const [props] = args;

    const {
        showOverlay,
        setHeaderState,
        isPasswordForgotSend,
        isMobile
    } = props;

    const { location: { pathname, state: { isForgotPassword } = {} } } = history;

    const state = {
        state: isSignedIn() ? STATE_LOGGED_IN : STATE_SIGNIN_WITH_OTP,
        // eslint-disable-next-line react/no-unused-state
        isPasswordForgotSend,
        isLoading: false
    };

    // if customer got here from forgot-password
    if (pathname !== '/forgot-password' && !isForgotPassword) {
        return state;
    }

    state.state = STATE_FORGOT_PASSWORD;

    setHeaderState({
        name: CUSTOMER_SUB_ACCOUNT,
        title: 'Forgot password',
        onBackClick: (e) => {
            history.push({ pathname: appendWithStoreCode(ACCOUNT_URL) });
            this.handleSignIn(e);
        }
    });

    if (isMobile) {
        history.push({ pathname: appendWithStoreCode(ACCOUNT_URL), state: { isForgotPassword: true } });

        return state;
    }

    showOverlay(CUSTOMER_ACCOUNT_OVERLAY_KEY);

    return state;
};

export class MyAccountOverlayContainer {
    containerFunctions = (originalMember, instance) => ({
        ...originalMember,
        handleOtpLogin: this.handleOtpLogin.bind(instance)
    });

    handleOtpLogin(e) {
        const { setHeaderState, device } = this.props;
        e?.preventDefault();
        e?.nativeEvent.stopImmediatePropagation();
        this.setState({ state: STATE_SIGNIN_WITH_OTP });

        setHeaderState({
            name: CUSTOMER_ACCOUNT,
            title: __('Sign in with OTP'),
            onBackClick: () => this.handleSignIn(e)
        });

        if (device.isMobile) {
            history.replace(appendWithStoreCode('/otp-login'));
        }
    }
}

const { containerFunctions } = new MyAccountOverlayContainer();

export default {
    'Component/MyAccountOverlay/Container': {
        'member-property': {
            containerFunctions
        },
        'static-member': {
            propTypes
        },
        'member-function': {
            redirectOrGetState
        }
    },
    'Component/MyAccountOverlay/Container/mapStateToProps': {
        function: mapStateToProps
    }
};
