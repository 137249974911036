import PropTypes from 'prop-types';

import { ProductType } from 'Type/ProductList.type';
import { fetchMutation } from 'Util/Request';

import ScalpMeterQuery from '../query/ScalpMeter.query';

const propTypes = (originalMember) => ({
    ...originalMember,
    scalpProductsInCart: PropTypes.arrayOf(ProductType).isRequired,
    transaction_id: PropTypes.string.isRequired
});

const triggerScripts = (args, callback, instance) => {
    const { scalpProductsInCart = [], transaction_id = '' } = instance.props;

    if (!scalpProductsInCart.length) {
        return callback.apply(args, instance);
    }
    const skus = scalpProductsInCart.reduce((all, p) => [...all, p.sku], []);
    fetchMutation(ScalpMeterQuery.saveScalpmeterPurchasedFromRecommendation({
        sku: skus.join(','),
        transaction_id
    }));

    return callback.apply(args, instance);
};

const mapStateToProps = (args, callback, _instance) => {
    const [state] = args;

    return {
        ...callback(...args),
        scalpProductsInCart: state.ScalpMeterReducer.inCart,
        transaction_id: state.ScalpMeterReducer.transaction_id
    };
};

export default {
    'Scandi/Component/CheckoutSuccess/Component/CheckoutSuccessComponent': {
        'member-function': {
            triggerScripts
        },
        'static-member': {
            propTypes
        }
    },
    'Scandi/Component/CheckoutSuccess/Component/mapStateToProps': {
        function: mapStateToProps
    }
};
