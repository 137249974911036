import BrowserDatabase from 'Util/BrowserDatabase';

import { ADD_TO_CART, triggerEvent, UTM_DATA } from '../../Gtm.config';

const addProductToCart = async (args, callback, instance) => {
    try {
        await callback.apply(instance, args);
        const {
            product,
            quantity = 1,
            addToCart,
            countryCode = ''
        } = instance.props;

        if ((!product || Object.keys(product).length === 0) && !addToCart) {
            return Promise.reject();
        }

        const {
            sku, name, price_range: {
                minimum_price: {
                    discount: { amount_off = 0, percent_off = 0 } = {},
                    regular_price = {},
                    final_price: { currency, value }
                } = {}
            } = {},
            quantity: wishlistQty = null
        } = product;

        const utmData = BrowserDatabase.getItem(UTM_DATA);

        triggerEvent({ ecommerce: null });

        const clevertapData = {
            'Product Name': product.name,
            'Product ID': product.sku,
            'Regular Price': `${regular_price?.currency} ${regular_price?.value}`,
            'Selling Price': `${currency} ${value}`,
            'Product Quantity': 1,
            'Country Code': countryCode,
            'Discount Percent': percent_off,
            'Discount Amount': amount_off
        };

        triggerEvent({
            event: ADD_TO_CART,
            ecommerce: {
                currency,
                value: value * (quantity || wishlistQty),
                items: [{
                    item_id: sku,
                    item_name: name,
                    currency,
                    price: value,
                    quantity: (quantity || wishlistQty)
                }]
            },
            utm_data: utmData,
            clevertapData
        });

        return Promise.resolve();
    } catch (error) {
        return Promise.reject(error);
    }
};

export default {
    'Scandi/Component/AddToCart/Container/AddToCartContainer': {
        'member-function': {
            addProductToCart
        }
    }
};
