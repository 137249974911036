/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-magic-numbers */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/media-has-caption */
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import TryNow from '../component/TryNow';
import {
    injectOrboScript
} from '../orbo.config';

import './Orbo.style.scss';

const Orbo = ({
    device,
    isOrboOpen,
    closeOrbo,
    product,
    data
}) => (
        <div block="OrboWrapper" mods={ { isMobile: device.isMobile, isOpen: isOrboOpen } }>
            <div block="OrboWrapper" elem="Video" mods={ { isMobile: device.isMobile, isOpen: isOrboOpen } }>
                <div block="OrboWrapper" elem="ProductTitle">{ product?.name }</div>
                <video id="videoStream" style={ { display: 'none' } } />
                <canvas id="output" height="640" width={ device.isMobile ? window.innerWidth - 23 : '480' } style={ { border: '1px solid' } } />
                <canvas id="wasm_canvas" style={ { display: 'none' } } />
                <div block="OrboWrapper" elem="Options">
                    { data }
                </div>
            </div>
            <button block="OrboWrapper" elem="CloseButton" onClick={ closeOrbo }>x</button>
        </div>
);

Orbo.propTypes = {
    isOrboOpen: PropTypes.bool.isRequired,
    closeOrbo: PropTypes.func.isRequired,
    product: PropTypes.object.isRequired,
    device: PropTypes.object.isRequired,
    data: PropTypes.object
};

Orbo.defaultProps = {
    data: null
};

const renderConfigurableOptions = (args, callback, instance) => {
    const {
        device,
        isOrboOpen,
        closeOrbo,
        product
    } = instance.props;

    const data = callback.apply(instance, args);

    return (
        <>
            { data }
            { ReactDOM.createPortal(
                <Orbo device={ device } isOrboOpen={ isOrboOpen } closeOrbo={ closeOrbo } product={ product } data={ data } />,
                document.body
            ) }
        </>
    );
};

const renderAddToCartButton = (args, callback, instance) => {
    const { product: { attributes: { try_on } = {} } = {}, product, getActiveProduct } = instance.props;

    if (!try_on || try_on.attribute_value.toString() !== '1') {
        return callback.apply(instance, args);
    }
    const productOrVariant = getActiveProduct();
    const { type_id } = productOrVariant;
    // eslint-disable-next-line fp/no-let
    let p = productOrVariant;
    if (type_id === 'configurable') {
        [p] = productOrVariant.variants;
    }

    return (
        <>
            { callback.apply(instance, args) }
            <div className="d-flex TryNow-Wrapper">
                <TryNow product={ product } productOrVariant={ p } />
            </div>
        </>
    );
};

const render = (args, callback, instance) => {
    const { product = {} } = instance.props;

    const { attributes: { try_on } = {} } = product;
    if (!try_on || try_on.attribute_value.toString() !== '1') {
        return callback.apply(instance, args);
    }
    injectOrboScript();
    return callback.apply(instance, args);
};

export default {
    'Scandi/Component/ProductActions/Component/ProductActionsComponent': {
        'member-function': {
            renderConfigurableOptions,
            renderAddToCartButton,
            // renderSkuAndStock,
            render
        }
    }
};
