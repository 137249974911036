export const RAZOR_PAY = 'razorpay';

export const loadScript = () => {
    return new Promise((resolve) => {
        const check = document.getElementById(RAZOR_PAY);
        if (check) {
            resolve(true);
        }
        const script = document.createElement("script");
        script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        script.setAttribute('id', RAZOR_PAY);
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
}