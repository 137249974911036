/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-magic-numbers */
import PropTypes from 'prop-types';
import { createRef, Suspense } from 'react';

import AddToCart from 'Component/AddToCart';
import CloseIcon from 'Component/CloseIcon';
import FIELD_TYPE from 'Component/Field/Field.config';
import FieldContainer from 'Component/Field/Field.container';
import Html from 'Component/Html';
import Image from 'Component/Image';
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import { Product } from 'Component/Product/Product.component';
import PRODUCT_TYPE from 'Component/Product/Product.config';
import StarIcon from 'Component/StarIcon';
import TextPlaceholder from 'Component/TextPlaceholder';
import { GRID_LAYOUT, LIST_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';
import { MixType } from 'Type/Common.type';
import { DeviceType } from 'Type/Device.type';
import { LayoutType } from 'Type/Layout.type';
import { LinkType } from 'Type/Router.type';
import { VALIDATION_INPUT_TYPE_NUMBER } from 'Util/Validator/Config';

import './ProductCard.style';
import './ProductCard.override.style';

/** @namespace Scandi/Component/ProductCard/Component/ProductCardComponent */
export class ProductCardComponent extends Product {
    static propTypes = {
        ...Product.propTypes,
        linkTo: LinkType,
        device: DeviceType.isRequired,
        thumbnail: PropTypes.string,
        isLoading: PropTypes.bool,
        children: PropTypes.element,
        layout: LayoutType,
        mix: MixType,
        renderContent: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
        hideWishlistButton: PropTypes.bool,
        isWishlistEnabled: PropTypes.bool.isRequired,
        hideCompareButton: PropTypes.bool,
        // eslint-disable-next-line react/no-unused-prop-types
        parameters: PropTypes.objectOf(PropTypes.string).isRequired,
        showSelectOptionsNotification: PropTypes.func.isRequired,
        registerSharedElement: PropTypes.func.isRequired,
        showVideoProduct: PropTypes.bool.isRequired
    };

    static defaultProps = {
        ...Product.defaultProps,
        thumbnail: '',
        linkTo: {},
        children: null,
        isLoading: false,
        mix: {},
        renderContent: false,
        hideWishlistButton: false,
        hideCompareButton: false,
        layout: GRID_LAYOUT
    };

    contentObject = {
        renderCardLinkWrapper: this.renderCardLinkWrapper.bind(this),
        pictureBlock: {
            picture: this.renderPicture.bind(this)
        },
        content: {
            review: this.renderReviews.bind(this),
            productPrice: this.renderPrice.bind(this),
            mainDetails: this.renderMainDetails.bind(this),
            additionalProductDetails: this.renderBrand.bind(this)
        }
    };

    imageRef = createRef();

    className = 'ProductCard';

    registerSharedElement = this.registerSharedElement.bind(this);

    registerSharedElement() {
        const { registerSharedElement } = this.props;
        registerSharedElement(this.imageRef);
    }

    // #region PRICE
    renderEmptyProductPrice() {
        return (
            <div
              block="ProductCard"
              elem="PriceWrapper"
              mods={ { isEmpty: true } }
            />
        );
    }

    renderPrice() {
        const {
            getActiveProduct,
            product: {
                type_id: baseType
            } = {}
        } = this.props;

        const {
            price_range: priceRange,
            type_id: typeId
        } = getActiveProduct();

        if (!priceRange) {
            return this.renderTextPlaceholder();
        }

        // If product is not a variant.
        const notConfigured = baseType !== PRODUCT_TYPE.configurable || typeId === baseType;

        return super.renderPrice(notConfigured);
    }
    // #endregion

    renderPicture(mix = {}) {
        const { product: { id, name }, thumbnail } = this.props;

        return (
            <Image
              imageRef={ this.imageRef }
              src={ thumbnail }
              alt={ name }
              ratio="custom"
              mix={ { block: 'ProductCard', elem: 'Picture', mix } }
              isPlaceholder={ !id }
            />
        );
    }

    renderProductCompareButton() {
        const {
            hideCompareButton
        } = this.props;

        if (hideCompareButton) {
            return null;
        }

        return this.renderCompareButton();
    }

    renderProductCardWishlistButton() {
        const { hideWishlistButton, isWishlistEnabled } = this.props;

        if (hideWishlistButton || !isWishlistEnabled) {
            return null;
        }

        return this.renderWishlistButton();
    }

    renderMainDetails() {
        try {
            const { product: { name }, getActiveProduct } = this.props;
            const { name: activeName } = getActiveProduct();
            const Name = activeName || name;

            return (
                <p
                  block="ProductCard"
                  elem="Name"
                  mods={ { isLoaded: !!Name } }
                >
                    <TextPlaceholder content={ Name } length="medium" />
                </p>
            );
        } catch (error) {
            const { product: { name } } = this.props;

            return (
                <p
                  block="ProductCard"
                  elem="Name"
                  mods={ { isLoaded: !!name } }
                >
                    <TextPlaceholder content={ name } length="medium" />
                </p>
            );
        }
    }

    renderCardLinkWrapper(children, mix = {}) {
        const { linkTo, product: { url } } = this.props;

        if (!url) {
            return (
                <div
                  block="ProductCard"
                  elem="Link"
                >
                    { children }
                </div>
            );
        }

        return (
            <Link
              block="ProductCard"
              elem="Link"
              to={ linkTo }
              onClick={ this.registerSharedElement }
              mix={ mix }
            >
              { children }
            </Link>
        );
    }

    requiresConfiguration() {
        const {
            product: {
                type_id: type,
                options = [],
                links_purchased_separately
            }
        } = this.props;

        const configureBundleAndGrouped = type === PRODUCT_TYPE.bundle || type === PRODUCT_TYPE.grouped;
        const configureConfig = type === PRODUCT_TYPE.configurable;
        //   && Object.keys(super.getConfigurableAttributes()).length !== Object.keys(this.getConfigurableAttributes()).length;
        const configureCustomize = options?.some(({ required = false }) => required);
        const configureDownloadableLinks = PRODUCT_TYPE.downloadable && links_purchased_separately === 1;

        return configureBundleAndGrouped || configureConfig || configureCustomize || configureDownloadableLinks;
    }

    renderAddToCartWrap() {
        const {
            layout,
            // showSelectOptionsNotification,
            inStock,
            product: { sku, type_id }
        } = this.props;

        if (!sku) {
            return null;
        }
        const { previewOpen } = this.state;

        if (!previewOpen && type_id === PRODUCT_TYPE.configurable && this.requiresConfiguration()) {
            return (
                    <button
                      block="Button AddToCart ProductCard-AddToCart"
                      mods={ { layout } }
                      // eslint-disable-next-line react/jsx-no-bind
                      onClick={ () => this.openPreview() }
                    >
                        { __('Preview Options') }
                    </button>
            );
        }

        if (!inStock) {
            return (
                    <button
                      disabled
                      block="Button AddToCart ProductCard-AddToCart"
                      mods={ { layout } }
                    >
                        { __('Sold Out') }
                    </button>
            );
        }

        return this.renderAddToCartButton(layout);
    }

    renderAddToCartButton(layout = GRID_LAYOUT) {
        const {
            addToCart,
            inStock,
            quantity,
            getActiveProduct
        } = this.props;

        return (
            <AddToCart
              mix={ { block: this.className, elem: 'AddToCart' } }
              addToCart={ addToCart }
              isDisabled={ !inStock }
              isIconEnabled={ false }
              layout={ layout }
              quantity={ quantity }
              product={ getActiveProduct() }
            />
        );
    }

    getConfigurableAttributes() {
        const filteredOptions = super.getConfigurableAttributes();

        return Object.fromEntries(Object.entries(filteredOptions).filter(([, option]) => {
            const { attribute_options: attributeOptions = {} } = option;

            return Object.values(attributeOptions).some(({ swatch_data: swatchData }) => swatchData);
        }));
    }

    state = {
        previewOpen: false,
        readMore: false,
        showCard: false
    };

    openPreview() {
        this.setState({
            previewOpen: true
        });
    }

    closePreview() {
        this.setState({
            previewOpen: false
        });
    }

    renderPreviewOptions() {
        const { product: { variants, configurable_options } } = this.props;
        if (variants === undefined || variants.length < 1) {
            return null;
        }
        // eslint-disable-next-line fp/no-let
        let name = 'Shade';
        // eslint-disable-next-line array-callback-return
        Object.values(configurable_options).map((option) => {
            name = option.attribute_label;
        });

        return (
            <div block="ProductCard" elem="PreviewOptions">
                <div block="ProductCard" elem="PreviewOptionsHeader">
                    <p block="ProductCard" elem="PreviewOptionsHeading">{ `Select ${name} (${variants.length})` }</p>
                    <button
                      block="ProductCard"
                      elem="PreviewOptionsCloseButton"
                      type="button"
                      // eslint-disable-next-line react/jsx-no-bind
                      onClick={ () => this.closePreview() }
                    >
                        <CloseIcon />
                    </button>
                </div>
                { this.renderConfigurableOptions() }
            </div>
        );
    }

    renderVisibleOnHover() {
        const { showVideoProduct } = this.props;
        const { previewOpen } = this.state;
        return (
            <>
            { previewOpen ? this.renderPreviewOptions() : null }
                <div block="ProductCard" elem="Footer">
                    { showVideoProduct && this.renderQuantityChanger() }
                    { this.renderAddToCartWrap() }
                </div>
            </>
        );
    }

    renderCardListContent() {
        const {
            children, layout, renderContent
        } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return this.renderCardLinkWrapper((
            <div block="ProductCard" elem="Link">
                <div block="ProductCard" elem="FigureReview">
                    <figure block="ProductCard" elem="Figure">
                        { this.renderPicture() }
                    </figure>
                </div>
                <div block="ProductCard" elem="Content" mods={ { layout } }>
                    <div block="ProductCard" elem="MainInfo">
                        { this.renderReviews() }
                        { this.renderBrand() }
                        { this.renderMainDetails() }
                    </div>
                    <div block="ProductCard" elem="AttributeWrapper">
                        { this.renderPrice() }
                        { this.renderConfigurableOptions() }
                    </div>
                    <div block="ProductCard" elem="ActionWrapper">
                        { this.renderAddToCartWrap() }
                        { this.renderProductActions() }
                    </div>
                    <div block="ProductCard" elem="AdditionalContent">
                        { children }
                    </div>
                </div>
            </div>
        ));
    }

    renderReviews() {
        const {
            product: {
                review_summary: {
                    rating_summary,
                    review_count
                } = {}
            }
        } = this.props;

        // const rating_summary = 67;
        // const review_count = 200;

        if (!rating_summary) {
            return (
                <div
                  block="ProductCard"
                  elem="Reviews"
                />
            );
        }

        const starCount = (rating_summary / 100) * 5;

        const rating = Array.from(Array(5 || starCount).keys());

        return (
            <div
              block="ProductCard"
              elem="Reviews"
            >
                <span
                  block="ProductCard"
                  elem="ReviewsStars"
                >
                    { rating.map((_, index) => <StarIcon key={ `index-${index}` } />) }
                </span>
                <span
                  block="ProductCard"
                  elem="ReviewsCount"
                >
                    <span>{ review_count }</span>
                    { ' ' }
                    <span>{ review_count > 1 ? 'Reviews' : 'Review' }</span>
                </span>
            </div>
        );
    }

    renderQuantityChanger() {
        const {
            quantity,
            minQuantity,
            maxQuantity,
            setQuantity,
            inStock,
            product: { type_id }
        } = this.props;

        if (type_id === PRODUCT_TYPE.grouped) {
            return null;
        }

        return (
            <FieldContainer
              type={ FIELD_TYPE.number }
              attr={ {
                  id: 'item_qty',
                  name: 'item_qty',
                  defaultValue: quantity,
                  max: maxQuantity,
                  min: minQuantity
              } }
              validationRule={ {
                  inputType: VALIDATION_INPUT_TYPE_NUMBER.numeric,
                  isRequired: true,
                  range: {
                      min: minQuantity,
                      max: maxQuantity
                  }
              } }
              isDisabled={ !inStock }
              mix={ { block: this.className, elem: 'Qty' } }
              events={ { onChange: setQuantity } }
              validateOn={ ['onChange'] }
            />
        );
    }

    renderOfferLabel() {
        const { getActiveProduct } = this.props;
        try {
            const { attributes: { product_badge: { attribute_value } } = {}, created_at, price_range: { minimum_price: { discount: { percent_off } } } } = getActiveProduct();
            // eslint-disable-next-line fp/no-let
            let label;

            if (attribute_value) {
                label = attribute_value;
            } else if (percent_off > 20) {
                label = 'offer';
            } else {
                const productDate = new Date(created_at);
                const targetDate = new Date();
                targetDate.setDate(targetDate.getDate() - 10);
                if (productDate > targetDate) {
                    label = 'new arrival';
                }
            }

            if (!label) {
                return null;
            }

            return (
                   <span
                     block="ProductCard-label ProductCard-label"
                     elem="left"
                     aria-label={ __('Save') }
                   >
                    { label }
                    { /* <div className="ProductCard-label-Triangle" /> */ }
                   </span>
            );
        } catch (error) {
            return null;
        }
    }

    renderMiddelOfferTab() {
        const { getActiveProduct } = this.props;
        try {
            const { price_range: { minimum_price: { discount: { percent_off } } } } = getActiveProduct();
            // eslint-disable-next-line fp/no-let
            let label;
            if (percent_off > 20) {
                label = 'FLAT 15% OFF';
            }

            if (label === undefined) {
                return (
                    <span
                      block="ProductCard-label"
                      elem="middle"
                      aria-label={ __('Save') }
                    >
                     FLAT 15% OFF
                    </span>
                );
            }

            return (
                   <span
                     block="ProductCard-label"
                     elem="middle"
                     aria-label={ __('Save') }
                   >
                    { label }
                   </span>
            );
        } catch (error) {
            return null;
        }
    }

    renderDiscountPercentage() {
        const { getActiveProduct } = this.props;
        try {
            const { price_range: { minimum_price: { discount: { percent_off } } } } = getActiveProduct();
            if (!percent_off) {
                return (
                    <span
                      block="ProductCard-label ProductCard-label"
                      elem="right"
                      aria-label={ __('Save') }
                    >
                     10% OFF
                    </span>
                );
            }

            return (
                   <span
                     block="ProductCard-label"
                     elem="right"
                     aria-label={ __('Save') }
                   >
                    { `${percent_off?.toFixed(0)}% OFF` }
                   </span>
            );
        } catch (error) {
            return null;
        }
    }

    renderProductSize() {
        return (
            <span block="ProductCard" elem="ProductSize" />
        );
    }

    renderUseOfProduct() {
        try {
            const {
                getActiveProduct,
                product: { attributes: { use_of_product: { attribute_value } } = {} }
            } = this.props;

            const { attributes: { use_of_product: { active_attribute_value } } = {} } = getActiveProduct();
            if (active_attribute_value || attribute_value) {
                return (
                    <span block="ProductCard" elem="UseOf">{ active_attribute_value || attribute_value }</span>
                );
            }

            return (
                <span block="ProductCard" elem="UseOf">100% Vegan | Cruelty Free | Halal Certified</span>
            );
        } catch (error) {
            return (
                <span block="ProductCard" elem="UseOf">100% Vegan | Cruelty Free | Halal Certified</span>
            );
        }
    }

    renderRating() {
        try {
            const { getActiveProduct, product: { rating_summary, review_count } } = this.props;
            const { rating_summary: active_rating_summary, review_count: active_review_count } = getActiveProduct();
            // eslint-disable-next-line fp/no-let
            let review;
            if (!review_count) {
                return (
                    <div className="ProductCard-Rating" />
                );
            }
            if (!active_rating_summary && active_review_count > 0) {
                if (active_review_count > 999) {
                    review = `${active_review_count / 1000}k`;
                } else {
                    review = active_review_count;
                }

                return (
                    <div className="ProductCard-Rating">
                        <div block="ProductCard-Rating" elem="card">
                            <span>{ active_rating_summary / 20 }</span>
                            <svg width="12px" height="12px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path style={ { fill: '#F09000' } } d="M10 1.3l2.388 6.722H18.8l-5.232 3.948 1.871 6.928L10 14.744l-5.438 4.154 1.87-6.928-5.233-3.948h6.412L10 1.3z" /></svg>
                        </div>
                        <span block="ProductCard-Rating" elem="review">{ review > 1 ? `${review} reviews` : `${review} review` }</span>
                    </div>
                );
            }
            if (!rating_summary && review_count < 1) {
                return (
                    <div className="ProductCard-Rating" />
                );
            }
            if (review_count > 999) {
                review = `${review_count / 1000}k`;
            } else {
                review = review_count;
            }

            return (
                <div className="ProductCard-Rating">
                    <div block="ProductCard-Rating" elem="card">
                        <span>{ rating_summary / 20 }</span>
                        <svg width="12px" height="12px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path style={ { fill: '#F09000' } } d="M10 1.3l2.388 6.722H18.8l-5.232 3.948 1.871 6.928L10 14.744l-5.438 4.154 1.87-6.928-5.233-3.948h6.412L10 1.3z" /></svg>
                    </div>
                    <span block="ProductCard-Rating" elem="review">{ review > 1 ? `${review} reviews` : `${review} review` }</span>
                </div>
            );
        } catch (error) {
            return null;
        }
    }

    handleReadMore() {
        const { readMore } = this.state;
        this.setState({ readMore: !readMore });
    }

    renderProductDescriptionTab(key) {
        const { getActiveProduct, product: { description } } = this.props;
        const { activeAescription } = getActiveProduct();
        const descriptionTag = activeAescription?.html || description?.html;
        if (!descriptionTag || descriptionTag === '') {
            return null;
        }
        const cleanDescription = descriptionTag.replace(/<\/?[^>]+(>|$)/g, '');
        const { readMore } = this.state;
        const readMoreText = readMore ? 'Read less' : 'Read more';

        return (
            <Suspense fallback={ null } key={ key }>
                <span block="ProductCardVideo-Info" elem="Heading">
                Description
                </span>
                <div className={ `ProductCardVideo-Info-Wrapper ${readMore ? 'active' : '' }` }>
                    <Html content={ cleanDescription } />
                </div>
                <button
                  key="ReadMoreButton"
                  block="ProductCardVideo-Info"
                  elem="ReadMore"
                  onClick={ () => this.handleReadMore() }
                >
                    { readMoreText }
                </button>
            </Suspense>
        );
    }

    renderCardContent() {
        const {
            renderContent, children, showVideoProduct, device: { isMobile }
        } = this.props;

        const { showCard } = this.state;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        if (showVideoProduct) {
            if (isMobile) {
                if (showCard) {
                    return (
                        <div
                          className="ProductCardVideo-overlap"
                        >
                            <style>{ '.Modal-Close{ display: none;} ' }</style>
                            <button
                              style={ { height: '30%', width: '100%' } }
                              onClick={ () => {
                                  this.setState({ showCard: false });
                              } }
                            />
                            <div className="ProductCardVideo-Link ProductCardVideo-Link_active">
                                { this.renderCardLinkWrapper((
                                    <>
                                        { this.renderOfferLabel() }
                                        <div block="ProductCardVideo" elem="FigureReview">
                                            <figure block="ProductCardVideo" elem="Figure">
                                                { this.renderPicture() }
                                            </figure>
                                        </div>
                                        <div block="ProductCardVideo" elem="Content">
                                            { this.renderMainDetails() }
                                            { /* { this.renderRating() } */ }
                                            { this.renderPrice() }
                                        </div>
                                        { children }
                                    </>
                                )) }
                                <div className="ProductCardVideo-DescriptionTab">
                                { this.renderProductDescriptionTab(1) }
                                </div>
                            </div>
                            { this.renderVisibleOnHover() }
                        </div>
                    );
                }

                return (
                    <div className="ProductCardVideo-Link_M">
                        <div block="ProductCardVideo" elem="FigureReview_M">
                            <figure block="ProductCardVideo" elem="Figure_M">
                                { this.renderPicture() }
                            </figure>
                        </div>
                        <div block="ProductCardVideo" elem="Content_M">
                            { this.renderMainDetails() }
                            { this.renderPrice() }
                        </div>
                        <button
                          block="ProductCardVideo"
                          elem="viewMore"
                          onClick={ () => {
                              this.setState({ showCard: true });
                          } }
                        >
                            Shop now
                        </button>
                    </div>
                );
            }

            return (
                <>
                    <div className="ProductCardVideo-Link">
                        { this.renderCardLinkWrapper((
                            <>
                                { this.renderOfferLabel() }
                                <div block="ProductCardVideo" elem="FigureReview">
                                    <figure block="ProductCardVideo" elem="Figure">
                                        { this.renderPicture() }
                                    </figure>
                                </div>
                                <div block="ProductCardVideo" elem="Content">
                                    { this.renderMainDetails() }
                                    { /* { this.renderRating() } */ }
                                    { this.renderPrice() }
                                </div>
                                { children }
                            </>
                        )) }
                        <div className="ProductCardVideo-DescriptionTab">
                        { this.renderProductDescriptionTab(1) }
                        </div>
                    </div>
                    { this.renderVisibleOnHover() }
                </>
            );
        }

        return (
            <>
                { this.renderCardLinkWrapper((
                    <>
                        { this.renderOfferLabel() }
                        { /* this.renderDiscountPercentage() */ }
                        <div block="ProductCard" elem="FigureReview">
                            <figure block="ProductCard" elem="Figure">
                                { this.renderPicture() }
                            </figure>
                        </div>
                        <div block="ProductCard" elem="Content">
                        { /* this.renderMiddelOfferTab() */ }
                        { this.renderMainDetails() }
                        { this.renderUseOfProduct() }
                        { /* { this.renderProductSize() } */ }
                        { /* { this.renderRating() } */ }
                        { /* this.renderReviews() */ }
                        { this.renderPrice() }
                        </div>
                        { children }
                    </>
                )) }
                { this.renderVisibleOnHover() }
            </>
        );
    }

    renderProductActions() {
        return (
            <div block="ProductCard" elem="ProductActions">
                { this.renderProductCardWishlistButton() }
                { this.renderProductCompareButton() }
            </div>
        );
    }

    render() {
        const {
            // children,
            mix,
            isLoading,
            layout
        } = this.props;

        if (layout === LIST_LAYOUT) {
            return (
                <li
                  block="ProductCard"
                  mods={ { layout } }
                  mix={ mix }
                >
                    <Loader isLoading={ isLoading } />
                    { this.renderCardListContent() }
                </li>
            );
        }

        return (
            <li
              block="ProductCard"
              mods={ { layout } }
              mix={ mix }
            >
                <Loader isLoading={ isLoading } />
                { this.renderCardContent() }
            </li>
        );
    }
}

export default ProductCardComponent;
