/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable react/jsx-no-bind */
import {
    lazy
} from 'react';
import { Route } from 'react-router-dom';

import {
    SWITCH_ITEMS_TYPE
} from 'Component/Router/Router.config';

export const withStoreRegex = (path) => window.storeRegexText.concat(path);

// eslint-disable-next-line max-len
export const SslcommerzePage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "search" */ '../route/SslcommerzePage'));

/** @namespace Sslcommerze/Plugin/RouterComponent/addSslcommerzeRouter */
// eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
export const addSslcommerzeRouter = (originalMember) => ([
    ...[originalMember][0],
    {
        // eslint-disable-next-line max-len
        component: <Route exact path={ withStoreRegex('/sslcommerz/payment/:returntype') } render={ (props) => <SslcommerzePage { ...props } /> } />,
        position: 100
    }
]);

export default {
    'Scandi/Component/Router/Component/RouterComponent': {
        'member-property': {
            [SWITCH_ITEMS_TYPE]: addSslcommerzeRouter
        }
    }
};
