/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const SHIPPING_STEP = 'SHIPPING_STEP';
export const BILLING_STEP = 'BILLING_STEP';
export const DETAILS_STEP = 'DETAILS_STEP';

export const CHECKOUT_URL = '/checkout';

export const PAYMENT_TOTALS = 'PAYMENT_TOTALS';

export const UPDATE_EMAIL_CHECK_FREQUENCY = 1500; // ms

export const SUCCESS_DATA = 'success_data';

export const PHONE_CODES = {
    pk: '+92',
    sa: '+966',
    qa: '+974',
    om: '+968',
    kw: '+965',
    bh: '+973',
    in: '+91',
    bd: '+880',
    ae: '+971',
    ng: '+009'
};
