/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
// import { FILTER } from 'Component/Header/Header.config';
// import Link from 'Component/Link';
import OfflineNotice from 'Component/OfflineNotice';
import { isCrawler, isSSR } from 'Util/Browser';

import './Header.style.scss';

export const render = (args, callback, instance) => {
    const { device } = instance.props;

    if (!device.isMobile) {
        return callback.apply(instance, args);
    }
    const {
        navigationState: { name, isHiddenOnMobile = false },
        isCheckout
    } = instance.props;

    return (
        <section
          block="Header"
          elem="Wrapper"
          mods={ { isPrerendered: isSSR() || isCrawler() } }
        >
            <header
              block="Header"
              mods={ { name, isHiddenOnMobile, isCheckout } }
              mix={ { block: 'FixedElement', elem: 'Top' } }
              ref={ instance.logoRef }
            >
                <nav block="Header" elem="Nav">
                    { instance.renderMobileHeader() }
                </nav>
                { /* <Link block="Menu" elem="SkinAnalyserLink" to="/skin-analyser">Skin Analyser</Link> */ }
                { name === 'default' ? instance.renderSearchField(true) : null }
            </header>
            <OfflineNotice />
        </section>
    );
};

/* export default {
    'Scandi/Component/Header/Component/HeaderComponent': {
        'member-function': {
            render
        }
    }
}; */
