/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { STAR_EMPTY, STAR_FULL, STAR_HALF_FULL } from './StarIcon.config';

import './StarIcon.style';

/** @namespace Scandi/Component/StarIcon/Component/StarIconComponent */
export class StarIconComponent extends PureComponent {
    static propTypes = {
        starFill: PropTypes.oneOf([STAR_HALF_FULL, STAR_EMPTY, STAR_FULL]).isRequired
    };

    getStarPath() {
        const { starFill } = this.props;

        if (starFill === STAR_EMPTY) {
            return (
                <path opacity="0.5" d="M12 0L14.6942 8.2918H23.4127L16.3593 13.4164L19.0534 21.7082L12 16.5836L4.94658 21.7082L7.64074 13.4164L0.587322 8.2918H9.30583L12 0Z" fill="#818284" />
            );
        }

        if (starFill === STAR_HALF_FULL) {
            return (
                <>
                <path d="M12 0L14.6942 8.2918H23.4127L16.3593 13.4164L19.0534 21.7082L12 16.5836L4.94658 21.7082L7.64074 13.4164L0.587322 8.2918H9.30583L12 0Z" fill="url(#paint0_linear_586_3113)" />
                <defs>
                <linearGradient id="paint0_linear_586_3113" x1="3" y1="12" x2="24" y2="12" gradientUnits="userSpaceOnUse">
                <stop stop-color="#F09000" />
                <stop offset="0.411458" stop-color="#F09000" />
                <stop offset="0.411558" stop-color="#C0C0C1" />
                <stop offset="0.661458" stop-color="#C0C0C1" />
                <stop offset="1" stop-color="#C0C0C1" />
                </linearGradient>
                </defs>
                </>
            );
        }

        if (starFill === STAR_FULL) {
            return (
                <path d="M12 0L14.6942 8.2918H23.4127L16.3593 13.4164L19.0534 21.7082L12 16.5836L4.94658 21.7082L7.64074 13.4164L0.587322 8.2918H9.30583L12 0Z" fill="#F09000" />
            );
        }

        return null;
    }

    render() {
        return (
            <svg
              block="StarIcon"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                { this.getStarPath() }
            </svg>
        );
    }
}

export default StarIconComponent;
