import { Field } from 'Util/Query';

/** @namespace PageBuilder/Query/GoogleMap/Query/GoogleMapQuery */
export class GoogleMapQuery {
    getQuery() {
        return new Field('googleMapConfig')
            .addField('apiKey');
    }
}

export default new GoogleMapQuery();
