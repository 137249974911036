import { applyMakeup } from '../orbo.config';

export const setActiveProduct = (args, callback, instance) => {
    const data = callback.apply(instance, args);
    // const { product } = instance.props;
    const [selectedProduct] = args;

    if (!selectedProduct?.attributes?.orbo_sku) {
        return data;
    }

    // eslint-disable-next-line max-len
    if (!selectedProduct?.attributes?.try_on || selectedProduct?.attributes?.try_on.attribute_value?.toString() !== '1') {
        return callback.apply(instance, args);
    }

    applyMakeup(selectedProduct.attributes.orbo_sku.attribute_value);

    return data;
};

export default {
    'Scandi/Route/ProductPage/Container/ProductPageContainer': {
        'member-function': {
            setActiveProduct
        }
    }
};
