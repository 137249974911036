import PropTypes from 'prop-types';

import BrowserDatabase from 'Util/BrowserDatabase';

import { triggerEvent, UTM_DATA, VIEW_CART } from '../../Gtm.config';

const mapStateToProps = (args, callback, _instance) => {
    const [state] = args;

    return {
        ...callback(...args),
        countryCode: state.ConfigReducer.code
    };
};

const state = (originalMember) => ({
    ...originalMember,
    gtmTriggered: false
});

const propTypes = (originalMember) => ({
    ...originalMember,
    countryCode: PropTypes.string.isRequired
});

const componentDidMount = (args, callback, instance) => {
    const {
        gtmTriggered
    } = instance.state;

    if (!gtmTriggered) {
        const {
            totals: {
                grand_total,
                quote_currency_code: currency,
                items
            } = {},
            countryCode
        } = instance.props;

        if (grand_total > 0 && currency !== '') {
            const itemsData = [];
            // eslint-disable-next-line fp/no-let
            let count = 1;
            const clevertapData = {
                'Country Code': countryCode
            };

            if (items) {
                items.forEach((d) => {
                    const {
                        qty,
                        sku,
                        price,
                        product: {
                            name,
                            price_range: {
                                minimum_price: {
                                    discount: { amount_off = 0, percent_off = 0 } = {}
                                } = {}
                            } = {},
                            url
                        } = {}
                    } = d;

                    if (price > 0) {
                        itemsData.push(
                            {
                                item_id: sku,
                                item_name: name,
                                currency,
                                price,
                                quantity: qty
                            }
                        );

                        clevertapData[`Product Name - ${count}`] = name;
                        clevertapData[`Product SKU - ${count}`] = sku;
                        clevertapData[`Product Price - ${count}`] = price;
                        clevertapData[`Product Quantity - ${count}`] = qty;
                        clevertapData[`Product URL - ${count}`] = url;
                        clevertapData[`Product Discount Amount - ${count}`] = amount_off;
                        clevertapData[`Product Discount Percent - ${count}`] = percent_off;
                        count++;
                    }
                });
            }
            const utmData = BrowserDatabase.getItem(UTM_DATA);

            triggerEvent({ ecommerce: null });

            triggerEvent({
                event: VIEW_CART,
                ecommerce: {
                    currency,
                    value: grand_total,
                    items: itemsData
                },
                utm_data: utmData,
                clevertapData
            });
        }

        instance.setState({ gtmTriggered: true });
    }

    return callback.apply(args, instance);
};

export default {
    'Scandi/Route/CartPage/Container/CartPageContainer': {
        'static-member': {
            propTypes
        },
        'member-function': {
            componentDidMount
        },
        'member-property': {
            state
        }
    },
    'Scandi/Route/CartPage/Container/mapStateToProps': {
        function: mapStateToProps
    }
};
