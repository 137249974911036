import PropTypes from 'prop-types';

import { triggerEvent, VIEW_CATEGORY } from '../../Gtm.config';

const render = (args, callback, instance) => {
    const {
        category = {},
        triggerGtm,
        gtmTriggered,
        countryCode
    } = instance.props;

    if (Object.keys(category).length && !gtmTriggered) {
        const clevertapData = {
            'Category ID': category.id,
            'Category Name': category.name,
            'Category URL': category.url,
            'Country Code': countryCode
        };

        triggerGtm();
        triggerEvent({ ecommerce: null });

        triggerEvent({
            event: VIEW_CATEGORY,
            clevertapData
        });
    }

    return callback(...args);
};

const propTypes = (originalMember) => ({
    ...originalMember,
    clevertapTriggered: PropTypes.bool.isRequired,
    triggerClevertap: PropTypes.func.isRequired
});

export default {
    'Scandi/Route/CategoryPage/Component/CategoryPageComponent': {
        'static-member': {
            propTypes
        },
        'member-function': {
            render
        }
    }
};
