// Let's use Scandi's BrowserDatabase utility for persistence
import BrowserDatabase from 'Util/BrowserDatabase';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';

export const POSTPAY_DATA = 'POSTPAY_DATA';

/** @namespace Postpay/Util/PaymentTokenPersistence/setPostpayPaymentData */
export const setPostpayPaymentData = (token) => {
    if (!token) {
        throw Error('Must specify data to set');
    }

    BrowserDatabase.setItem(token, POSTPAY_DATA, ONE_MONTH_IN_SECONDS);
};

/** @namespace Postpay/Util/PaymentTokenPersistence/getPostpayPaymentData */
export const getPostpayPaymentData = () => {
    const token = BrowserDatabase.getItem(POSTPAY_DATA);

    if (!token) {
        return null;
    }

    return token;
};
