import { Field } from 'Util/Query';

const getVerifyOtpMutation = (args) => {
    const [options] = args;
    const { phone_number, otp } = options;

    return new Field('verifyOtp')
        .addArgument('phone_number', 'String!', phone_number)
        .addArgument('otp', 'String!', otp)
        .addFieldList([
            'success',
            'message',
            'token'
        ]);
};

const getMobileSignInMutation = (args) => {
    const [options] = args;
    const { phone_number } = options;

    return new Field('sendOtp')
        .addArgument('phone_number', 'String!', phone_number)
        .addFieldList([
            'success',
            'message',
            'is_customer'
        ]);
};

export default {
    'Scandi/Query/MyAccount/Query/MyAccountQuery': {
        'member-function': {
            getMobileSignInMutation,
            getVerifyOtpMutation
        }
    }
};
