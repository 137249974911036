import Router from 'Component/Router';
import ScrollToTopButton from 'Component/ScrollToTopButton';
import {
    App as SourceApp
} from 'SourceComponent/App/App.component';

import './App.override.style';

/** @namespace Scandi/Component/App/Component/AppComponent */
export class AppComponent extends SourceApp {
    injectComment() {
        // const comment = document.createComment('Powered by ScandiPWA (scandipwa.com)');
        // document.querySelector('html').appendChild(comment);
    }

    renderRouter() {
        return (
            <>
                <style>
                    { `
                        :root {
                            --screen-inner-width: ${ window.innerWidth }px;
                        }
                    ` }
                </style>
                <Router key="router" />
            </>
        );
    }

    rootComponents = [
        this.renderRouter.bind(this)
        // this.renderSharedTransition.bind(this)
    ];

    removeIframeFromLocal() {
        if (window.location.href.includes('localhost')) {
            document.body.classList.add('isLocal');
        }
    }

    render() {
        this.removeIframeFromLocal();
        return (
            <>
            { this.renderContextProviders() }
            <ScrollToTopButton />
            </>
        );
    }
}

export default AppComponent;
