export default {
    BDT: { min: 0, max: 0 },
    INR: { min: 0, max: 0 },
    PKR: { min: 0, max: 0 },
    OMR: { min: 2, max: 2 },
    QAR: { min: 2, max: 2 },
    AED: { min: 2, max: 2 },
    SAR: { min: 2, max: 2 },
    BHD: { min: 2, max: 2 },
    KWD: { min: 2, max: 2 }
};
