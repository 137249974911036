import { FETCH_PROFILE, triggerEvent } from '../../Gtm.config';

const onCustomerSaveFetchMutationThen = (args, callback) => {
    const [{ updateCustomer: { customer } }] = args;

    const clevertapData = {
        Site: {
            Name: `${customer.firstname} ${customer.lastname}`
        }
    };

    triggerEvent({
        event: FETCH_PROFILE,
        clevertapData
    });

    callback(...args);
};

export default {
    'Component/MyAccountCustomerPopup/Container/MyAccountCustomerPopupContainer/onCustomerSave/fetchMutation/then': {
        function: onCustomerSaveFetchMutationThen
    }
};
