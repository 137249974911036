import { getStaticReducers as baseGetStaticReducers } from 'SourceStore/index';
import CategoriesPopup from 'Store/CategoriesPopup/CategoriesPopup.reducer';
import ModalReducer from 'Store/ModalReducer/ModalReducer.reducer';

/** @namespace Scandi/Store/Index/getStaticReducers */
export const getStaticReducers = () => ({
    ...baseGetStaticReducers(),
    CategoriesPopupReducer: CategoriesPopup,
    ModalReducer
});

export default function injectStaticReducers(store) {
    Object.entries(getStaticReducers()).forEach(
        ([name, reducer]) => store.injectReducer(name, reducer)
    );

    return store;
}
