import CheckoutQuery from 'Query/Checkout.query';
import { isSignedIn } from 'Util/Auth';
import { getGuestQuoteId } from 'Util/Cart';
import { fetchMutation } from 'Util/Request';

import { loadScript, RAZOR_PAY } from '../../razorpay.config';
import RazorpayQuery from '../query/Razorpay.query';

const savePaymentMethodAndPlaceOrder = async (args, callback, instance) => {
    const [paymentInformation] = args;

    const { paymentMethod: { code, additional_data, purchase_order_number } } = paymentInformation;
    const guest_cart_id = !isSignedIn() ? getGuestQuoteId() : '';

    const { billingAddress } = instance.state;

    if (RAZOR_PAY !== code) {
        await callback(...args);
        return;
    }

    try {
        const {
            razorpayOrder: {
                key_id,
                amount,
                rzp_order,
                order_id,
                quote_currency
            }
        } = await fetchMutation(
            RazorpayQuery.getCreateRazorpayPaymentOrderMutation(
                !isSignedIn() ? { guest_cart_id } : {}
            )
        );

        const res = await loadScript();

        if (!res) {
            throw new Error('Unknow error occured while loading razorpay');
        }

        const options = {
            key: key_id, // Enter the Key ID generated from the Dashboard
            amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            currency: quote_currency,
            name: 'LAFZ',
            description: 'Lafz Transaction',
            image: '',
            order_id: rzp_order,
            notes: {
                merchant_order_id: '',
                merchant_quote_id: order_id
            },
            async handler(response) {
                await fetchMutation(CheckoutQuery.getSetPaymentMethodOnCartMutation({
                    guest_cart_id,
                    payment_method: {
                        code,
                        additional_data,
                        purchase_order_number,
                        [code]: {
                            razorpay_order_id: response.razorpay_order_id,
                            razorpay_payment_id: response.razorpay_payment_id,
                            razorpay_signature: response.razorpay_signature
                        }
                    }
                }));
                const orderData = await fetchMutation(CheckoutQuery.getPlaceOrderMutation(guest_cart_id));
                const { placeOrder: { order: { order_id } } } = orderData;
                instance.setDetailsStep(order_id);
            },
            modal: {
                ondismiss() {
                    instance._handleError({ message: 'Payment cancelled' });
                }
            },
            prefill: {
                name: `${billingAddress.firstname} ${billingAddress.lastname}`,
                email: billingAddress.email,
                contact: billingAddress.telephone
            }
        };
        // eslint-disable-next-line no-undef
        const rzp1 = new Razorpay(options);
        rzp1.open();
    } catch (e) {
        instance._handleError(e);
    }
};

// Afain, we need to export the plugin configuration:
export default {
    'Scandi/Route/Checkout/Container/CheckoutContainer': {
        'member-function': {
            savePaymentMethodAndPlaceOrder
        }
    }
};
